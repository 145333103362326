import i18n from '~/i18n'
import { getError, getErrorEmail } from './errorsHandling'

export const savedDefault = {
  color: 'primary',
  text: i18n.t('FormView.saved'),
}

export function errorDefault(error) {
  return {
    color: 'error',
    text: getError(error),
  }
}

export function errorEmail(error) {
  return {
    color: 'error',
    text: getErrorEmail(error),
  }
}
