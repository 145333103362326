export class MenuCategoryModel {
  name: string | null
  nameEN: string | null
  isActive: string | null
  orderOnDay: string | null
  id: number | null
  priority: string | null
  preferenceID: string | null
  menuInactive: string | null
  menuCategoryRef: string | null

  constructor(data, menuCategoryRef) {
    this.name = data.name || null
    this.nameEN = data.nameEN || null
    this.isActive = data.isActive || false
    this.orderOnDay = data.orderOnDay || null
    this.id = data.id || null
    this.priority = data.priority || null
    this.preferenceID = data.preferenceID || null
    this.menuInactive = null

    this.menuCategoryRef = menuCategoryRef
  }

  toMap() {
    const result: Partial<{ name, nameEN, isActive, orderOnDay, id, preferenceID, priority }> = {
      name: this.name?.trim() || null,
      nameEN: this.nameEN?.trim() || null,
      isActive: this.isActive || false,
      orderOnDay: this.orderOnDay || null,
      id: this.id,
    }
    if (this.preferenceID)
      result.preferenceID = this.preferenceID

    if (this.priority)
      result.priority = this.priority

    return result
  }
}

export function mapMenuCategory(menuCategoriesDoc) {
  return menuCategoryDoc => new MenuCategoryModel(
    menuCategoryDoc,
    menuCategoriesDoc.ref,
  )
}

export function mapMenuCategories(menuCategoriesDoc) {
  return menuCategoriesDoc.data()
    .menuCategories
    .map(mapMenuCategory(menuCategoriesDoc))
}
