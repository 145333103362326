<script setup lang="ts">
import {
  mdiAccountGroup,
  mdiBell,
  mdiBook,
  mdiCalendarClock,
  mdiCartVariant,
  mdiCashRegister,
  mdiCityVariantOutline,
  mdiClipboardCheckMultipleOutline,
  mdiFileDocumentMultiple,
  mdiFinance,
  mdiFoodForkDrink,
  mdiHandshake,
  mdiInformationOutline,
  mdiLogout,
  mdiMessage,
  mdiOfficeBuilding,
  mdiOfficeBuildingMarkerOutline,
  mdiPuzzle,
  mdiRoutes,
  mdiTicketPercentOutline,
  mdiViewDashboard,
  mdiWeatherNight,
  mdiWeatherSunny,
} from '@mdi/js'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'
import i18n from '~/i18n'
import { useDrawerStore } from '~/store/drawer'
import { useMessages } from '~/store/messages'
import { useReportBugOrSuggestion } from '~/store/reportBugOrSuggestion'
import { useVuex } from '~/useVuex'
import { isSandwiczSzop } from '../../helpers/company'
import { isOwner, ROLE } from '../../helpers/roles'
import { mapDateFromFirebaseFormat } from '../../helpers/time'
import { useVuetify } from '../../useVuetify'

const drawerStore = useDrawerStore()
const { visible } = storeToRefs(drawerStore)

const ModeratorPanel = [
  {
    icon: mdiViewDashboard,
    title: i18n.t('Navigation.admin'),
    link: '/moderator',
  },
  {
    icon: mdiOfficeBuilding,
    title: i18n.t('Navigation.companies'),
    link: '/companies',
  },
  {
    icon: mdiMessage,
    title: i18n.t('Navigation.messages'),
    link: '/messages',
  },
  {
    divider: true,
  },
  {
    icon: mdiLogout,
    title: i18n.t('Navigation.sign-out'),
    link: '/sign-out',
  },
]
const ManagerPanel = [
  {
    icon: mdiRoutes,
    title: i18n.t('Navigation.routes'),
    link: '/routes',
  },
  // {
  //   icon: mdiOfficeBuildingMarkerOutline,
  //   title: i18n.t('Navigation.suppliers'),
  //   link: '/suppliers',
  // },
  {
    icon: mdiFoodForkDrink,
    title: i18n.t('Navigation.menu'),
    link: '/menu',
  },
  {
    icon: mdiCalendarClock,
    title: i18n.t('Navigation.news'),
    link: '/news',
  },
  {
    icon: mdiFileDocumentMultiple,
    title: i18n.t('Navigation.invoices'),
    link: '/invoices',
  },
  {
    icon: mdiOfficeBuildingMarkerOutline,
    title: i18n.t('DeliversSellsView.title'),
    link: '/delivers-sells',
  },
  {
    icon: mdiCityVariantOutline,
    title: i18n.t('Navigation.facilities'),
    subGroup: true,
    items: [
      {
        icon: mdiCityVariantOutline,
        title: i18n.t('Navigation.suppliersSubsidy'),
        link: '/suppliersSubsidy',
      },
      {
        icon: mdiHandshake,
        title: i18n.t('Navigation.subsidy'),
        link: '/subsidy',
      },
    ],
  },
  {
    icon: mdiAccountGroup,
    title: i18n.t('Navigation.employees'),
    link: '/employees',
    subGroup: true,
    items: [
      {
        icon: mdiAccountGroup,
        title: i18n.t('Navigation.employees'),
        link: '/employees',
      },
      {
        title: i18n.t('Navigation.employeesRanking'),
        link: '/employeesRanking',
      },
    ],
  },
  {
    icon: mdiClipboardCheckMultipleOutline,
    title: i18n.t('OnlineTransactionReports.title'),
    subGroup: true,
    items: [
      {
        title: i18n.t('Navigation.stats'),
        link: '/ordersStatistic',
      },
      {
        title: i18n.t('Navigation.productStats'),
        link: '/productsStatistic',
      },
      {
        title: i18n.t('Navigation.notyfication'),
        link: '/stats',
      },
      {
        title: i18n.t('Navigation.orders'),
        link: '/orders',
      },
      {
        title: i18n.t('OnlineTransactionReports.title2'),
        link: '/onlineTransactionReports',
      },
      {
        title: i18n.t('PromotionsReports.title'),
        link: '/promotionsReports',
      },
    ],
  },
  {
    icon: mdiTicketPercentOutline,
    title: i18n.t('Navigation.coupons'),
    subGroup: true,
    items: [
      {
        title: i18n.t('Navigation.coupons'),
        link: '/coupons',
      },
      {
        title: i18n.t('Navigation.couponStatistics'),
        link: '/promo-code-statistics',
      },
      {
        title: i18n.t('Navigation.couponsUsed'),
        link: '/promo-code-usage',
      },
    ],
  },
  {
    icon: mdiOfficeBuildingMarkerOutline,
    title: i18n.t('Navigation.suppliers'),
    subGroup: true,
    items: [
      {
        title: i18n.t('table.search'),
        link: '/suppliers',
      },
      {
        title: i18n.t('Navigation.map'),
        link: '/suppliers-map',
      },
      {
        title: i18n.t('Navigation.customersMap'),
        link: '/customers-map',
      },
    ],
  },
]
const GetDeliverPanel = [
  {
    icon: mdiRoutes,
    title: i18n.t('Navigation.routes'),
    link: '/routes',
  },
  // {
  //   icon: mdiOfficeBuildingMarkerOutline,
  //   title: i18n.t('Navigation.suppliers'),
  //   link: '/suppliers',
  // },
  {
    icon: mdiFoodForkDrink,
    title: i18n.t('Navigation.menu'),
    link: '/menu',
  },
  {
    icon: mdiCalendarClock,
    title: i18n.t('Navigation.news'),
    link: '/news',
  },
  {
    icon: mdiFileDocumentMultiple,
    title: i18n.t('Navigation.invoices'),
    link: '/invoices',
  },
  {
    icon: mdiOfficeBuildingMarkerOutline,
    title: i18n.t('DeliversSellsView.title'),
    link: '/delivers-sells',
  },
  {
    icon: mdiAccountGroup,
    title: i18n.t('Navigation.employees'),
    link: '/employees',
    subGroup: true,
    items: [
      {
        icon: mdiAccountGroup,
        title: i18n.t('Navigation.employees'),
        link: '/employees',
      },
      {
        title: i18n.t('Navigation.employeesRanking'),
        link: '/employeesRanking',
      },
    ],
  },
  {
    icon: mdiClipboardCheckMultipleOutline,
    title: i18n.t('OnlineTransactionReports.title'),
    subGroup: true,
    items: [
      {
        title: i18n.t('Navigation.stats'),
        link: '/ordersStatistic',
      },
      {
        title: i18n.t('Navigation.productStats'),
        link: '/productsStatistic',
      },
      {
        title: i18n.t('Navigation.notyfication'),
        link: '/stats',
      },
      {
        title: i18n.t('Navigation.orders'),
        link: '/orders',
      },
      {
        title: i18n.t('OnlineTransactionReports.title2'),
        link: '/onlineTransactionReports',
      },
      {
        title: i18n.t('PromotionsReports.title'),
        link: '/promotionsReports',
      },
    ],
  },
  {
    icon: mdiTicketPercentOutline,
    title: i18n.t('Navigation.coupons'),
    subGroup: true,
    items: [
      {
        title: i18n.t('Navigation.coupons'),
        link: '/coupons',
      },
      {
        title: i18n.t('Navigation.couponStatistics'),
        link: '/promo-code-statistics',
      },
      {
        title: i18n.t('Navigation.couponsUsed'),
        link: '/promo-code-usage',
      },
    ],
  },
  {
    icon: mdiCityVariantOutline,
    title: i18n.t('Navigation.facilities'),
    subGroup: true,
    items: [
      {
        icon: mdiCityVariantOutline,
        title: i18n.t('Navigation.suppliersSubsidy'),
        link: '/suppliersSubsidy',
      },
      {
        icon: mdiHandshake,
        title: i18n.t('Navigation.subsidy'),
        link: '/subsidy',
      },
    ],
  },
  {
    icon: mdiOfficeBuildingMarkerOutline,
    title: i18n.t('Navigation.suppliers'),
    subGroup: true,
    items: [
      {
        title: i18n.t('table.search'),
        link: '/suppliers',
      },
      {
        title: i18n.t('Navigation.map'),
        link: '/suppliers-map',
      },
      {
        title: i18n.t('Navigation.customersMap'),
        link: '/customers-map',
      },

    ],
  },

]
const DefaultPanel = [
  {
    icon: mdiLogout,
    title: i18n.t('Navigation.sign-out'),
    link: '/sign-out',
  },
]
const CustomerManagerPanel = [
  {
    icon: mdiAccountGroup,
    title: i18n.t('Navigation.employees'),
    link: '/subsidyBudget',
  },
  {
    icon: mdiCityVariantOutline,
    title: i18n.t('Navigation.suppliersSubsidy'),
    link: '/customersSubsidy',
  },
  {
    icon: mdiFinance,
    title: i18n.t('Navigation.subsidy'),
    link: '/subsidyReport',
  },
]
const AdminPanel = [
  {
    icon: mdiOfficeBuilding,
    title: i18n.t('Navigation.company'),
    link: '/company',
  },
  {
    icon: mdiRoutes,
    title: i18n.t('Navigation.routes'),
    link: '/routes',
  },
  {
    icon: mdiOfficeBuildingMarkerOutline,
    title: i18n.t('Navigation.suppliers'),
    subGroup: true,
    items: [
      {
        title: i18n.t('table.search'),
        link: '/suppliers',
      },
      {
        title: i18n.t('Navigation.map'),
        link: '/suppliers-map',
      },
      {
        title: i18n.t('Navigation.customersMap'),
        link: '/customers-map',
      },

    ],
  },
  // {
  //   icon: mdiAccountMultiplePlus,
  //   title: i18n.t('Navigation.newUsers'),
  //   link: '/new-users',
  // },
  {
    icon: mdiAccountGroup,
    title: i18n.t('Navigation.employees'),
    link: '/employees',
    subGroup: true,
    items: [
      {
        icon: mdiAccountGroup,
        title: i18n.t('Navigation.employees'),
        link: '/employees',
      },
      {
        title: i18n.t('Navigation.employeesRanking'),
        link: '/employeesRanking',
      },
    ],
  },
  {
    icon: mdiFoodForkDrink,
    title: i18n.t('Navigation.menu'),
    link: '/menu',
  },
  {
    icon: mdiCalendarClock,
    title: i18n.t('Navigation.news'),
    link: '/news',
  },
  {
    icon: mdiFileDocumentMultiple,
    title: i18n.t('Navigation.invoices'),
    link: '/invoices',
  },
  {
    icon: mdiOfficeBuildingMarkerOutline,
    title: i18n.t('DeliversSellsView.title'),
    link: '/delivers-sells',
  },
  {
    icon: mdiCashRegister,
    title: i18n.t('PaymentsLastWorkingDay.title'),
    link: '/payment-last-working-day',
  },
  {
    icon: mdiCityVariantOutline,
    title: i18n.t('Navigation.facilities'),
    subGroup: true,
    items: [
      {
        icon: mdiCityVariantOutline,
        title: i18n.t('Navigation.suppliersSubsidy'),
        link: '/suppliersSubsidy',
      },
      {
        icon: mdiHandshake,
        title: i18n.t('Navigation.subsidy'),
        link: '/subsidy',
      },
    ],
  },
  {
    icon: mdiTicketPercentOutline,
    title: i18n.t('Navigation.coupons'),
    subGroup: true,
    items: [
      {
        title: i18n.t('Navigation.coupons'),
        link: '/coupons',
      },
      {
        title: i18n.t('Navigation.couponStatistics'),
        link: '/promo-code-statistics',
      },
      {
        title: i18n.t('Navigation.couponsUsed'),
        link: '/promo-code-usage',
      },
    ],
  },
  {
    icon: mdiClipboardCheckMultipleOutline,
    title: i18n.t('OnlineTransactionReports.title'),
    subGroup: true,
    items: [
      {
        title: i18n.t('Navigation.stats'),
        link: '/ordersStatistic',
      },
      {
        title: i18n.t('Navigation.productStats'),
        link: '/productsStatistic',
      },
      {
        title: i18n.t('Navigation.notyfication'),
        link: '/stats',
      },
      {
        title: i18n.t('Navigation.orders'),
        link: '/orders',
      },
      {
        title: i18n.t('OnlineTransactionReports.title2'),
        link: '/onlineTransactionReports',
      },
      {
        title: i18n.t('PromotionsReports.title'),
        link: '/promotionsReports',
      },
    ],
  },
]

const { getState } = useVuex()

const { setTheme } = useVuetify()

const navigationWithoutCompany = [
  {
    icon: mdiOfficeBuilding,
    title: i18n.t('Navigation.company'),
    link: '/company',
  },
  { divider: true },
  {
    icon: mdiLogout,
    title: i18n.t('Navigation.sign-out'),
    link: '/sign-out',
  },
]

const isDark = ref(false)
const showAgreementDialog = ref(false)

const userData = getState('userData', 'app')
const user = getState('user', 'app')

const reportBugOrSuggestionStore = useReportBugOrSuggestion()
const {
  reportBugOrSuggestion,
  reportBugOrSuggestionSolved,
  reportBugOrSuggestionWaitingCustomerAnswer,
  reportBugOrSuggestionActive,
  reportBugOrSuggestionWithoutCities,
  unsubscribe,
} = storeToRefs(reportBugOrSuggestionStore)

const company = getState('company', 'company')

const messagesStore = useMessages()
const {
  cateringOrdersToDo,
  unsubscribe1: unsubscribeCateringOrders1,
  unsubscribe2: unsubscribeCateringOrders2,
} = storeToRefs(messagesStore)

const reportBugOrSuggestionCmp = computed(() => [
  ...(reportBugOrSuggestion.value || []),
  ...(reportBugOrSuggestionSolved.value || []),
  ...(reportBugOrSuggestionActive.value || []),
  ...(reportBugOrSuggestionWaitingCustomerAnswer.value || []),
  ...(reportBugOrSuggestionWithoutCities.value || []),
])

const isReportBugOrSuggestion = computed(() => {
  const isActiveFilter = item => (item?.status == 'toDo' || item?.status == 'active' || item?.status == 'solved') && item?.problemType !== 'bug'

  return (reportBugOrSuggestionCmp.value || []).filter(isActiveFilter).length
})

const isCateringOrderToDo = computed(() => {
  return (cateringOrdersToDo.value || []).length
})

const agreementText = computed(() => (isOwner(userData.value?.role)
  ? i18n.t('CompanyView.agreementDescriptionOwner')
  : i18n.t('CompanyView.agreementDescription')),
)

const menuItems = computed(() => {
  let additionalNav: {
    icon: string
    title: string
    subGroup?: boolean
    link?: string
    items?: {
      icon?: string
      title: string
      link: string
    }[]
    divider?: boolean
  }[] = []

  if (isSandwiczSzop(userData.value?.company?.id)) {
    additionalNav = [
      {
        icon: mdiPuzzle,
        title: i18n.t('OnlineTransactionReports.title3').toString(),
        subGroup: true,
        items: [
          {
            icon: mdiBook,
            title: i18n.t('MissingCompaniesView.title').toString(),
            link: '/missingCompanies',
          },
        ],
      },
    ]
  }

  if (user.value.displayName == ROLE.CUSTOMER && userData.value.role == ROLE.MANAGER)
    return [...CustomerManagerPanel, ...additionalNav]

  switch (userData.value?.role) {
    case ROLE.OWNER:
      if (userData.value?.company)
        return [...AdminPanel, ...additionalNav]

      return navigationWithoutCompany
    case ROLE.GET_DELIVER:
      return [...GetDeliverPanel, ...additionalNav]
    case ROLE.MANAGER:
      return [...ManagerPanel, ...additionalNav]
    case ROLE.MODERATOR:
      return ModeratorPanel
    default:
      return DefaultPanel
  }
})

function onShowAgreementDialog() {
  if (
    company.value
    && userData.value
    && !unsubscribe.value
    && userData.value?.profile?.cities
  ) {
    reportBugOrSuggestionStore.getReportBugOrSuggestion(userData.value, user.value)
  }

  if (
    userData.value
    && company.value?.agreement
    && Object.values(company.value?.agreement).includes(false)
  ) {
    const timeToAcceptAgreement = mapDateFromFirebaseFormat(
      company.value?.timeToAcceptAgreement,
    )

    if (localStorage?.timeToAcceptAgreement !== timeToAcceptAgreement?.toString())
      showAgreementDialog.value = true
  }
}

function close() {
  localStorage.timeToAcceptAgreement = mapDateFromFirebaseFormat(
    company.value?.timeToAcceptAgreement,
  )

  showAgreementDialog.value = false
}

function toggleTheme2() {
  setTheme(!isDark.value)
  localStorage.dark = JSON.stringify(!isDark.value)
  isDark.value = !isDark.value
}

function getCateringOrdersData() {
  if (!unsubscribeCateringOrders1.value)
    messagesStore.getCateringOrdersToDo()

  if (!unsubscribeCateringOrders2.value && company.value)
    messagesStore.getCompanyCateringOrders(company.value.reference)
}
watch(company, () => {
  if (company.value) {
    onShowAgreementDialog()
    getCateringOrdersData()
  }
})

onMounted(() => {
  const mq = window.matchMedia('(prefers-color-scheme: dark)')

  const isDarkData
    = localStorage.dark == null
      ? mq.matches
      : JSON.parse(localStorage.dark)
  isDark.value = isDarkData
  if (company.value) {
    onShowAgreementDialog()
    getCateringOrdersData()
  }
})

function onChange(drawer) {
  drawerStore.setDrawer(drawer)
}

// @ts-expect-error
const version = __APP_VERSION__
</script>

<template>
  <v-navigation-drawer
    :value="visible"
    class="d-print-none"
    app
    width="245px"
    @input="onChange"
  >
    <v-list
      shaped
      dense
    >
      <v-list-item>
        <v-row>
          <img
            class="logo-radius"
            src="/landing/logo_icon.webp"
            rel="preload"
          >

          <h3 :dark="isDark">
            {{ $t("App.title1") }}
          </h3>
        </v-row>
      </v-list-item>

      <br>

      <v-list-item
        v-if="company"
        color="secondary"
        to="/reportBugOrSuggestion"
      >
        <v-badge
          v-if="isReportBugOrSuggestion"
          color="red"
          :content="isReportBugOrSuggestion"
        >
          <v-icon>
            {{ mdiBell }}
          </v-icon>
        </v-badge>

        <v-icon v-else>
          {{ mdiBell }}
        </v-icon>

        <span class="pl-4 text-subtitle-2">
          {{ $t("Navigation.reportBugOrSuggestion") }}
        </span>
      </v-list-item>

      <v-list-item
        v-if="company"
        color="secondary"
        to="/catering-orders"
      >
        <v-badge
          v-if="isCateringOrderToDo"
          color="red"
          :content="isCateringOrderToDo"
        >
          <v-icon>
            {{ mdiCartVariant }}
          </v-icon>
        </v-badge>

        <v-icon v-else>
          {{ mdiCartVariant }}
        </v-icon>

        <span class="pl-4 text-subtitle-2">
          {{ $t("Navigation.cateringOrders") }}
        </span>
      </v-list-item>

      <v-list-item-group>
        <!-- @vue-expect-error -->
        <TheMenuItem
          v-for="(item, index) in menuItems"
          :key="index"
          :item="item"
        />
      </v-list-item-group>
    </v-list>

    <v-spacer />

    <template #append>
      <v-divider />

      <v-list-item
        color="secondary"
        dense
        to="/deliver-instruction"
        target="_blank"
      >
        <v-icon>
          {{ mdiInformationOutline }}
        </v-icon>

        <span class="pl-4 text-subtitle-2">
          {{ $t("instructions") }}
        </span>
      </v-list-item>

      <v-divider />

      <v-list-item
        color="secondary"
        dense
        @click.stop="toggleTheme2"
      >
        <v-icon>
          {{ isDark
            ? mdiWeatherSunny
            : mdiWeatherNight }}
        </v-icon>

        <span class="pl-4 text-subtitle-2">
          {{ $t("darkTheme") }}
        </span>
      </v-list-item>

      <v-divider />

      <v-list-item
        color="secondary"
        dense
        to="/sign-out"
      >
        <v-icon>{{ mdiLogout }}</v-icon>

        <span class="pl-4 text-subtitle-2">
          {{ $t("Navigation.sign-out") }}
        </span>
      </v-list-item>

      <v-divider />

      <v-list-item
        dense
        class="text-subtitle-2"
      >
        {{ version }}
      </v-list-item>
    </template>

    <v-dialog
      v-model="showAgreementDialog"
      scrollable
      max-width="600px"
      @click.stop="close"
      @keydown.esc="close"
      @click:outside="close"
    >
      <TheDialogInfo
        :loading="false"
        :title="$t('CompanyView.agreementInfo').toString()"
        :on-close="close"
      >
        <span>
          {{ agreementText }}
        </span>
      </TheDialogInfo>
    </v-dialog>
  </v-navigation-drawer>
</template>

<style scoped>
.logo-radius {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  margin-left: 10px;
}
</style>
