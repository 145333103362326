import { computed, getCurrentInstance } from 'vue'

export function useVuex() {
  // @ts-expect-error getCurrentInstance
  const { $root } = getCurrentInstance().proxy

  /**
   * @deprecated Use getAction and getState instead
   */
  const { $store } = $root

  const getAction = (action, namespace) => (...args) => {
    if (!namespace) {
      $store.dispatch(
        action,
        ...args,
      )
    }
    else {
      $store.dispatch(
        `${namespace}/${action}`,
        ...args,
      )
    }
  }

  const getState = (state, namespace) => {
    if (!namespace)
      return computed(() => $store.state[state])

    return computed(() => $store.state[namespace][state])
  }

  const getGetter = (getter, namespace) => {
    if (!namespace)
      return computed(() => $store.getters[getter])

    return computed(() => $store.getters[namespace][getter])
  }

  return {
    $store,
    getAction,
    getState,
    getGetter,
  }
}
