import type { DocumentReference, Timestamp } from 'firebase/firestore'
import type { Ref } from 'vue'
import type { OrdersStatistic } from '../models/OrdersStatisticModel2'
import { getDoc, getDocs } from 'firebase/firestore'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { dateFormat, dateToTimestamp, getEndOfDay, getStartOfDay, toDateDayJs } from '~/helpers/time'
import type { MenuCategoryModel } from '~/models/MenuCategoryModel'
import { mapMenuCategories } from '~/models/MenuCategoryModel'
import store from '~/store'
import { getCollectionOrdersStatistic, menuCategoriesRef } from '../firestoreWrappers'
import { errorDefault } from '../helpers/snackbar'
import { mapOrdersStatistic } from '../models/OrdersStatisticModel2'

export const useOrdersStatistic = defineStore('ordersStatistic', () => {
  interface OrdersStatisticData {
    [city: string]: {
      [date: string]: OrdersStatistic[]
    }
  }

  const data: Ref<OrdersStatisticData | null> = ref(null)
  const categories: Ref<MenuCategoryModel[]> = ref([])
  const loading = ref(false)
  const error = ref(null)

  function resetState() {
    data.value = null
    categories.value = []
    loading.value = false
    error.value = null
  }

  function getOrdersStatisticForDay(company: DocumentReference, date: Date, city: string) {
    const mappedDate = toDateDayJs(date).format(dateFormat)

    if (data.value && data.value[city] && data.value[city][mappedDate])
      return

    error.value = null
    loading.value = true

    const onSuccess = ({ docs }) => {
      if (!data.value)
        data.value = {}

      if (!data.value[city])
        data.value = { ...data.value, [city]: {} }

      data.value[city] = {
        ...data.value[city],
        [mappedDate]: docs.map(mapOrdersStatistic),
      }

      error.value = null
      loading.value = false
    }

    const onError = (error: any) => {
      console.error(error)

      error.value = error
      loading.value = false
    }

    const dateStart = dateToTimestamp(getStartOfDay(date))
    const dateEnd = dateToTimestamp(getEndOfDay(date))

    getDocs(getCollectionOrdersStatistic(
      company,
      dateStart,
      dateEnd,
      city,
    ))
      .then(onSuccess)
      .catch(onError)
  }

  function getOrdersStatistic(company: DocumentReference, startDate: Timestamp, endDate: Timestamp, date: string, city: string) {
    error.value = null
    loading.value = true

    const onSuccess = ({ docs }) => {
      if (!data.value)
        data.value = {}

      if (!data.value[city])
        data.value[city] = {}

      data.value[city][date] = docs.map(mapOrdersStatistic)
      error.value = null
      loading.value = false
    }

    const onError = (error) => {
      console.error(error)
      error.value = error
      loading.value = false
      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(errorArg),
      )
    }

    getDocs(getCollectionOrdersStatistic(
      company,
      startDate,
      endDate,
      city,
    ))
      .then(onSuccess)
      .catch(onError)
  }

  const getCategories = (company: DocumentReference | null) => {
    if (!company)
      return

    const onSuccess = (doc) => {
      categories.value = mapMenuCategories(doc)
    }

    const onError = (error) => {
      console.error(error)
    }

    getDoc(menuCategoriesRef(company))
      .then(onSuccess)
      .catch(onError)
  }

  return {
    data,
    categories,
    loading,
    error,
    resetState,
    getOrdersStatisticForDay,
    getOrdersStatistic,
    getCategories,
  }
})
