import type { Ref } from 'vue'
import type { MissingCompanyModel } from '../models/MissingCompanyModel'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import store from '.'
import { getMissingCompanies as getMissingCompaniesFun, updatetMissingCompany as updatetMissingCompanyFun } from '../firebase/firestoreMissingCompanies'
import { errorDefault } from '../helpers/snackbar'
import { mapMissingCompany } from '../models/MissingCompanyModel'

function mapMissingCompanies(item, status) {
  return missingCompany => (missingCompany?.reference?.id === item?.reference?.id
    ? {
        ...missingCompany,
        status,
      }
    : missingCompany
  )
}

export const useMissingCompanies = defineStore('missingCompanies', () => {
  const missingCompanies: Ref<MissingCompanyModel[] | null> = ref(null)

  function resetState() {
    missingCompanies.value = null
  }

  function getMissingCompanies(company) {
    store.dispatch('shared/init')

    const onSuccess = ({ docs }) => {
      missingCompanies.value = docs?.map(mapMissingCompany)
      store.dispatch('shared/success')
    }

    const onError = (error) => {
      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    getMissingCompaniesFun(company)
      .then(onSuccess)
      .catch(onError)
  }
  function updateMissingCompanyState(userData, item, status) {
    store.dispatch('shared/init')

    const onSuccess = () => {
      missingCompanies.value = missingCompanies.value
        ? missingCompanies.value?.map(mapMissingCompanies(
          item,
          status,
        ))
        : missingCompanies.value
      store.dispatch('shared/success')
    }

    const onError = (error) => {
      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    updatetMissingCompanyFun(
      item,
      status,
      userData,
    ).then(onSuccess).catch(onError)
  }

  return {
    missingCompanies,

    resetState,
    getMissingCompanies,
    updateMissingCompanyState,
  }
})
