import type { Ref } from 'vue'
import { addDoc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import store from '.'
import { checkCouponCode, collectionCoupons, getCouponsCollection, getNextCouponsCollection } from '../firebase/firestoreCoupons'
import { getIdFromRef } from '../helpers/getIdFromRef'
import { errorDefault, savedDefault } from '../helpers/snackbar'
import { mapCoupons } from '../models/CouponModel'

function onErrorShared(error) {
  console.error(error)
  store.dispatch(
    'snackbar/showSnackbar',
    errorDefault(error),
  )
}

export function generateRandomText() {
  const charset
      = 'abcdefghijklmnopqrstuvwxyz0123456789'
  const length = 6
  let retVal = ''

  for (let charsNumber = charset.length, index = 0; index < length; ++index)
    retVal += charset.charAt(Math.round(Math.random() * charsNumber))

  return retVal
}

async function checkCode(promoCode) {
  const response = await checkCouponCode(promoCode)

  return response.empty
}

export const useCoupons = defineStore('coupons', () => {
  const data: Ref = ref(null)
  const generatedData = ref(null)
  const exists = ref(null)
  const size = ref(null)
  const lastVisible = ref(null)
  const fetchLoading = ref(false)
  const fetchError = ref(null)
  const createLoading = ref(false)
  const createError: any = ref(null)

  function resetState() {
    data.value = null
    generatedData.value = null
    exists.value = null
    size.value = null
    lastVisible.value = null
    fetchLoading.value = false
    fetchError.value = null
    createLoading.value = false
    createError.value = null
  }

  function getCoupons(userData) {
    fetchError.value = null
    fetchLoading.value = true

    const onSuccess = ({ docs, size: sizeArg }) => {
      size.value = sizeArg
      lastVisible.value = docs[docs.length - 1]
      fetchLoading.value = false
      fetchError.value = null
      if (data.value) {
        data.value = [
          ...data.value,
          ...docs.map(mapCoupons),
        ]
      }
      else {
        data.value = docs.map(mapCoupons)
      }
    }

    const onError = (error) => {
      console.error(error)
      fetchLoading.value = false
      fetchError.value = error

      onErrorShared(error)
    }

    const promise = lastVisible.value
      ? getNextCouponsCollection(
        userData.company,
        lastVisible.value,
      )
      : getCouponsCollection(userData.company)

    promise
      .then(onSuccess)
      .catch(onError)
  }

  async function createCoupons(userData, dataArg, count) {
    createError.value = null
    createLoading.value = true

    let newCouponRef

    try {
      const dataCopy = dataArg
      dataCopy.lastUpdateByUser = userData.reference
      dataCopy.lastUpdateTime = serverTimestamp()

      const codes = []
      const newData = []
      let code = ''
      let empty = false

      for (let index = 0; index < count; index++) {
        do {
          code = generateRandomText()
          empty = await checkCode(code)
        } while (!empty)
        // @ts-expect-error
        codes[index] = code

        dataCopy.promoCode = code

        newCouponRef = await addDoc(
          collectionCoupons,
          {
            ...dataCopy.toMap(),
            createdByUser: userData.reference,
          },
        )

        const copyData = dataCopy
        copyData.reference = newCouponRef
        // @ts-expect-error
        newData.push(copyData)
        code = ''
      }

      dataArg.reference = newCouponRef
      createLoading.value = false
      createError.value = null
      data.value = [
        ...(data.value || []),
        ...newData,
      ]
      // generatedData.value = newData.codes

      store.dispatch(
        'snackbar/showSnackbar',
        savedDefault,
      )
    }
    catch (error) {
      if (newCouponRef) {
        // await deleteDoc(newCouponRef)
      }

      createLoading.value = false
      createError.value = error

      onErrorShared(error)
    }
  }

  function editCoupon(userData, newData) {
    createError.value = null
    createLoading.value = true

    const onError = (error) => {
      console.error(error)
      createLoading.value = false
      createError.value = error

      onErrorShared(error)
    }

    const onSuccess = () => {
      const findByReference = ({ reference: referenceValue }) => getIdFromRef(referenceValue) == getIdFromRef(newData.reference)
      const couponIndex = data.value.findIndex(findByReference)
      const newCoupon = [...data.value]
      newCoupon[couponIndex] = newData
      createLoading.value = false
      createError.value = null
      data.value = [...newCoupon]

      store.dispatch(
        'snackbar/showSnackbar',
        savedDefault,
      )
    }

    newData.lastUpdateByUser = userData.reference
    newData.lastUpdateTime = serverTimestamp()
    updateDoc(
      newData.reference,
      newData.toMap(),
    )
      .then(onSuccess)
      .catch(onError)
  }

  return {
    data,
    generatedData,
    exists,
    size,
    lastVisible,
    fetchLoading,
    fetchError,
    createLoading,
    createError,
    resetState,
    getCoupons,
    createCoupons,
    editCoupon,

  }
})
