import { getDoc, getDocs, query, serverTimestamp, where, writeBatch } from 'firebase/firestore'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import { firestore } from '~/firebaseCore'
import { ROLE } from '~/helpers/roles'
import { mapCustomers } from '~/models/CustomerModel'
import { mapSuppliers } from '~/models/SupplierModel'
import { collectionUsers } from '../firestoreWrappers'

const getSupplierDoc = reference => getDoc(reference)

export const useManagerRoutes = defineStore('managerRoutes', () => {
  const employees: any = ref([])
  const suppliers: any = ref([])
  const loading = ref(false)
  const error = ref(null)

  function resetState() {
    employees.value = []
    suppliers.value = []
    loading.value = false
    error.value = null
  }

  function getEmployees(userData, suppliersArg) {
    error.value = null
    loading.value = true

    const employeesArg = []
    const uniqueSuppliers = []

    const onMidSuccess = (docs) => {
      docs.docs.forEach((doc) => {
        const mappedDoc = mapCustomers(doc)
        // @ts-expect-error
        if (!employeesArg.map(employee => employee.reference.id).includes(mappedDoc.reference.id) && mappedDoc.reference.id !== userData.reference.id) {
          // @ts-expect-error
          employeesArg.push(mappedDoc)

          // @ts-expect-error
          mappedDoc.work.suppliers.forEach((supplier) => {
            // @ts-expect-error
            if (!uniqueSuppliers.map(uniqueSupplier => uniqueSupplier.id).includes(supplier.id))
            // @ts-expect-error
              uniqueSuppliers.push(supplier)
          })
        }
      })
    }

    const onFailure = (errorArg) => {
      console.error(errorArg)
      loading.value = false
      error.value = errorArg
    }

    const promises = suppliersArg.map(supplier => getDocs(
      query(
        collectionUsers,
        where('work.suppliers', 'array-contains', supplier),
        where('role', '==', ROLE.MANAGER),
      ),
    ),
    )

    Promise.all(promises)
      .then((results) => {
        results.forEach(onMidSuccess)
        const supplierPromises = uniqueSuppliers.map(uniqueSupplier => getSupplierDoc(uniqueSupplier))

        Promise.all(supplierPromises).then((supplierResults) => {
          loading.value = false
          error.value = null
          employees.value = employeesArg
          suppliers.value = supplierResults.map(mapSuppliers(null))
        })
      })
      .catch(onFailure)
  }

  async function updateSupplierSubsidy(userData, reference, subsidy, price) {
    error.value = null
    loading.value = true

    const onSuccess = () => {
      loading.value = false
      error.value = null
    }

    const onFailure = (errorArg) => {
      loading.value = false
      error.value = errorArg
    }

    const batch = writeBatch(firestore)

    batch.update(reference, {
      subsidy: { ...subsidy, subsidyPrice: price },
      lastUpdateByUser: userData.reference,
      lastUpdateTime: serverTimestamp(),
    })

    await batch.commit()
      .then(onSuccess)
      .catch(onFailure)
  }

  async function updateCustomerSubsidy(userData, reference, price) {
    error.value = null
    loading.value = true

    const onSuccess = () => {
      loading.value = false
      error.value = null
    }

    const onFailure = (errorArg) => {
      loading.value = false
      error.value = errorArg
    }

    const batch = writeBatch(firestore)

    batch.update(reference, {
      subsidyBudget: price,
      lastUpdateByUser: userData.reference,
      lastUpdateTime: serverTimestamp(),
    })

    await batch.commit()
      .then(onSuccess)
      .catch(onFailure)
  }

  async function updateActive(userData, reference, active) {
    error.value = null
    loading.value = true

    const onSuccess = () => {
      loading.value = false
      error.value = null
    }

    const onFailure = (errorArg) => {
      loading.value = false
      error.value = errorArg
    }

    const batch = writeBatch(firestore)

    batch.update(reference, {
      isActive: active,
      lastUpdateByUser: userData.reference,
      lastUpdateTime: serverTimestamp(),
    })

    await batch.commit()
      .then(onSuccess)
      .catch(onFailure)
  }

  return {
    employees,
    suppliers,
    loading,
    error,
    resetState,
    getEmployees,
    updateSupplierSubsidy,
    updateCustomerSubsidy,
    updateActive,

  }
})
