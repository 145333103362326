import type { DocumentReference } from 'firebase/firestore'
import type { Ref } from 'vue'
import type { InvoiceModel } from '../models/InvoiceModel'
import { addDoc, deleteDoc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import store from '.'
import { collectionInvoices, getInvoicesCollection } from '../firebase/firestoreInvoices'
import { getIdFromRef } from '../helpers/getIdFromRef'
import { errorDefault, savedDefault } from '../helpers/snackbar'
import { mapData } from '../models/InvoiceModel'

function onErrorShared(error) {
  console.error(error)
  store.dispatch(
    'snackbar/showSnackbar',
    errorDefault(error),
  )
}
export const useInvoices = defineStore('invoices', () => {
  const data: Ref<InvoiceModel[] | null> = ref(null)

  function resetState() {
    data.value = null
  }

  function getInvoices(company) {
    store.dispatch('shared/init')

    const onSuccess = ({ docs }) => {
      data.value = docs.map(mapData)
      store.dispatch('shared/success')
    }

    const onError = (error) => {
      console.error(error)
      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    getInvoicesCollection(company)
      .then(onSuccess)
      .catch(onError)
  }

  async function createInvoice(userData, dataArg) {
    let newInvoiceRef

    try {
      dataArg.lastUpdateByUser = userData.reference
      dataArg.lastUpdateTime = serverTimestamp()

      newInvoiceRef = await addDoc(
        collectionInvoices,
        {
          ...dataArg.toMap(),
          createdByUser: userData.reference,
        },
      )

      dataArg.reference = newInvoiceRef

      data.value = [
        ...(data.value || []),
        dataArg,
      ]

      store.dispatch(
        'snackbar/showSnackbar',
        savedDefault,
      )
    }
    catch (error) {
      if (newInvoiceRef)
        await deleteDoc(newInvoiceRef)

      onErrorShared(error)
    }
  }

  function editInvoice(userData, newData: InvoiceModel) {
    const onError = (error) => {
      console.error(error)
      onErrorShared(error)
    }

    const onSuccess = () => {
      const newInvoice = data.value?.map((invoice) => {
        if (getIdFromRef(invoice.reference) == getIdFromRef(newData.reference))
          return newData

        return invoice
      }) || null
      data.value = newInvoice

      store.dispatch(
        'snackbar/showSnackbar',
        savedDefault,
      )
    }

    newData.lastUpdateByUser = userData.reference
    newData.lastUpdateTime = serverTimestamp()

    updateDoc(
      newData.reference as DocumentReference,
      newData.toMap(),
    )
      .then(onSuccess)
      .catch(onError)
  }

  return {
    data,
    resetState,
    getInvoices,
    createInvoice,
    editInvoice,
  }
})
