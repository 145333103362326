import { addDoc, arrayRemove, arrayUnion, deleteDoc, getDoc, getDocs, serverTimestamp, updateDoc, writeBatch } from 'firebase/firestore'
import { getDownloadURL, ref as refFirebase, uploadString } from 'firebase/storage'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import i18n from '~/i18n'
import store from '.'
import { firestore, storage } from '../firebaseCore'
import {
  collectionMenu,
  getMenuByDay,
  menuByCategoryRef,
  menuCategoriesRef as menuCategoriesRefFun,
} from '../firestoreWrappers'
import { onCreate, onUpdate } from '../helpers/actions'
import { generateRandomText } from '../helpers/generateRandomText'
import { getIdFromRef } from '../helpers/getIdFromRef'
import { errorDefault, savedDefault } from '../helpers/snackbar'
import { createAndUploadImage } from '../helpers/uploadImage'
import { mapMenuCategories, mapMenuModel, MenuCategoryModel } from '../models/MenuModel'

const getIdRaw = ({ id }) => id

export const useMenus = defineStore('menus', () => {
  const menuCategoriesRef = ref (null)
  const menuCategories: any = ref (null)
  const menuCategoriesLoading = ref (false)
  const menus: any = ref (null)
  const menusInactive: any = ref (null)
  const menuOfDay = ref({
    monday: null,
    tuesday: null,
    wednesday: null,
    thursday: null,
    friday: null,
    saturday: null,
    sunday: null,
  })
  const fetchMenuOfDayLoading = ref(false)
  const fetchMenuOfDayError = ref(null)
  const fetchLoading = ref(false)
  const fetchError = ref(null)
  const createLoading = ref(false)
  const createError: any = ref(null)

  function resetState() {
    menuCategoriesRef.value = null
    menuCategories.value = null
    menuCategoriesLoading.value = false
    menus.value = null
    menusInactive.value = null
    fetchMenuOfDayLoading.value = false
    fetchMenuOfDayError.value = null
    fetchLoading.value = false
    fetchError.value = null
    createLoading.value = false
    createError.value = null
  }

  function getMenuCategories(userData) {
    menuCategoriesLoading.value = true

    const onError = (error) => {
      console.error(error)
      menuCategoriesLoading.value = false

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    const onSuccess = (menuCategoriesArg) => {
      if (menuCategoriesArg.data().menuCategories?.length) {
        const tmpMenuCategories = mapMenuCategories(menuCategoriesArg)
        const menuCategoriesData = tmpMenuCategories.sort((a, b) => a?.priority - b?.priority)

        menuCategoriesLoading.value = false
        menuCategories.value = menuCategoriesData
        menuCategoriesRef.value = menuCategories.ref

        getMenu(userData, 0, true)
      }
      else {
        menuCategoriesLoading.value = false
        menuCategories.value = []
        menuCategoriesRef.value = null
      }
    }

    getDoc(menuCategoriesRefFun(userData.company))
      .then(onSuccess)
      .catch(onError)
  }

  function getMenu(userData, categoryIndex, isActive) {
    fetchError.value = null
    fetchLoading.value = true

    const onSuccess = ({ docs }) => {
      fetchLoading.value = false
      fetchError.value = null
      if (isActive) {
        menus.value = {
          ...menus.value || {},
          [categoryIndex]: docs.map(mapMenuModel),
        }
      }
      else {
        menusInactive.value = {
          ...menusInactive.value || {},
          [categoryIndex]: docs.map(mapMenuModel),
        }
      }
    }

    const onError = (error) => {
      console.error(error)
      fetchLoading.value = false
      fetchError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        error,
      )
    }

    getDocs(menuByCategoryRef(
      userData.company,
      menuCategories.value[categoryIndex].id,
      isActive,
    ))
      .then(onSuccess)
      .catch(onError)
  }

  function getMenuFromDay(userData, day) {
    fetchMenuOfDayError.value = null
    fetchMenuOfDayLoading.value = true
    const onSuccess = ({ docs }) => {
      fetchMenuOfDayLoading.value = false
      fetchMenuOfDayError.value = null
      menuOfDay.value[day] = docs.map(mapMenuModel)
    }

    const onError = (error) => {
      console.error(error)
      fetchMenuOfDayLoading.value = false
      fetchMenuOfDayError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        error,
      )
    }
    getDocs(getMenuByDay(
      userData.company,
      day,
    ))
      .then(onSuccess)
      .catch(onError)
  }

  async function createNewFood(userData, menu, menuCategoryIndex) {
    createError.value = null
    createLoading.value = true

    let newMenuRef
    let uploaded

    const onError = async (error) => {
      if (uploaded)
        await deleteDoc(uploaded.ref)

      if (newMenuRef)
        await deleteDoc(newMenuRef)

      createLoading.value = false
      createError.value = error
      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    try {
      menu.categoryID = menuCategories.value[menuCategoryIndex].id
      menu.companyRef = userData.company
      menu.lastUpdateByUser = userData.reference
      menu.lastUpdateTime = serverTimestamp()

      const imgData = menu.image
      menu.image = null

      const imageHighData = menu.imageHigh
      menu.imageHigh = null

      const newMenuData = {
        ...menu.toMap(),
        ...onCreate(userData.reference),
      }

      newMenuRef = await addDoc(
        collectionMenu,
        newMenuData,
      )

      if (imgData) {
        const { imageUrl, imagePath, uploadedData } = await createAndUploadImage(
          userData,
          newMenuRef,
          imgData,
          storage,
        )

        menu.image = imageUrl
        menu.imagePath = imagePath
        uploaded = uploadedData
      }

      if (imageHighData) {
        const { imageUrl, imagePath, uploadedData } = await createAndUploadImage(
          userData,
          newMenuRef,
          imageHighData,
          storage,
          'High',
        )

        menu.imageHigh = imageUrl
        menu.imageHighPath = imagePath
        uploaded = uploadedData
      }

      const onSuccessUpdateMenu = () => {
        menu.menuRef = newMenuRef

        createLoading.value = false
        createError.value = null
        if (menu.isActive) {
          if (menus.value === null)
            menus.value = { 0: [menu] }
          else if (menus.value[menuCategoryIndex])
            menus.value[menuCategoryIndex].push(menu)
        }
        else if (menusInactive.value === null) {
          menus.value = [menu]
        }
        else if (menus.value[menuCategoryIndex]) {
          menusInactive.value[menuCategoryIndex].push(menu)
        }
        store.dispatch(
          'snackbar/showSnackbar',
          savedDefault,
        )
      }

      const updateData = {
        image: menu.image,
        imagePath: menu.imagePath,
        ...onUpdate(userData.reference),
      }

      updateDoc(
        newMenuRef,
        updateData,
      )
        .then(onSuccessUpdateMenu)
        .catch(onError)
    }
    catch (error) {
      await onError(error)
    }
  }

  async function updateFood(userData, menu, menuCategoryIndex, changeStatus) {
    createError.value = null
    createLoading.value = true

    const onError = (error) => {
      console.error(error)
      createLoading.value = false
      createError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    const updateFoodFunc = (menuData, menuCategoryIndexData) => {
      const onSuccess = () => {
        const getIndex = () => {
          const byRef = ({ menuRef }) => getIdFromRef(menuRef) == getIdFromRef(menuData.menuRef)

          if (menu.isActive) {
            const selectedMenu = changeStatus
              ? menusInactive.value
              : menus.value

            return selectedMenu
              ? selectedMenu[menuCategoryIndex].findIndex(byRef)
              : null
          }
          const selectedMenu = changeStatus
            ? menus.value
            : menusInactive.value

          return selectedMenu
            ? selectedMenu[menuCategoryIndex].findIndex(byRef)
            : null
        }

        const dishIndex = getIndex()
        createLoading.value = false
        createError.value = null
        if (dishIndex !== null) {
          if (menuData.isActive) {
            if (changeStatus) {
              menusInactive.value[menuCategoryIndexData].splice(
                dishIndex,
                1,
              )
            }
            else {
              const tmpMenus = menus.value
              const copy = [...tmpMenus[menuCategoryIndexData]]
              copy[dishIndex] = menuData
              tmpMenus[menuCategoryIndexData] = copy
              menus.value = tmpMenus
            }
          }
          else if (!menuData.isActive) {
            if (changeStatus) {
              menus.value[menuCategoryIndexData].splice(
                dishIndex,
                1,
              )
            }
            else {
              const tmpMenus = { ...menusInactive.value }
              tmpMenus[menuCategoryIndexData][dishIndex] = menuData
              menusInactive.value = tmpMenus
            }
          }
        }
      }

      menu.lastUpdateByUser = userData.reference
      menu.lastUpdateTime = serverTimestamp()

      updateDoc(
        menu.menuRef,
        menu.toUpdateMap(),
      )
        .then(onSuccess)
        .catch(onError)
    }

    if (!menu.image || typeof menu.image === 'string') {
      updateFoodFunc(
        menu,
        menuCategoryIndex,
      )
    }
    else if (!menu.imagePath && menu.image) {
      let uploaded
      let uploadedHigh
      try {
        const imagePath = `${getIdFromRef(userData.company)}/${getIdFromRef(menu.reference)}${generateRandomText()}`
        const imageHighPath = `${imagePath}High`

        menu.imagePath = imagePath
        menu.imageHighPath = imageHighPath

        uploaded = await uploadString(
          refFirebase(
            storage,
            imagePath,
          ),
          menu.image.dataUrl,
          'data_url',
        )

        uploadedHigh = await uploadString(
          refFirebase(
            storage,
            imageHighPath,
          ),
          menu.imageHigh.dataUrl,
          'data_url',
        )

        const imageUrl = await getDownloadURL(uploaded.ref)
        menu.image = imageUrl

        const imageHighUrl = await getDownloadURL(uploadedHigh.ref)
        menu.imageHigh = imageHighUrl

        updateFoodFunc(
          menu,
          menuCategoryIndex,
        )
      }
      catch (error) {
        if (uploaded)
          await deleteDoc(uploaded.ref)

        if (uploadedHigh)
          await deleteDoc(uploadedHigh.ref)

        onError(error)
      }
    }
    else {
      try {
        let uploadedHigh
        const uploaded = await uploadString(
          refFirebase(
            storage,
            menu.imagePath,
          ),
          menu.image.dataUrl,
          'data_url',
        )

        if (menu.imageHighPath) {
          uploadedHigh = await uploadString(
            refFirebase(
              storage,
              menu.imageHighPath,
            ),
            menu.imageHigh.dataUrl,
            'data_url',
          )
        }
        else {
          const imageHighPath = `${menu.imagePath}High`
          menu.imageHighPath = imageHighPath
          uploadedHigh = await uploadString(
            refFirebase(
              storage,
              imageHighPath,
            ),
            menu.imageHigh.dataUrl,
            'data_url',
          )
        }

        const imageUrl = await getDownloadURL(uploaded.ref)
        const imageHighUrl = await getDownloadURL(uploadedHigh.ref)
        menu.image = imageUrl
        menu.imageHigh = imageHighUrl
        updateFoodFunc(
          menu,
          menuCategoryIndex,
        )
      }
      catch (error) {
        onError(error)
      }
    }
  }

  function createNewCategory(userData, categoryName, categoryNameEN, active, selectedDate) {
    createError.value = null
    createLoading.value = true

    const onSuccess = newMenuCategory => () => {
      createLoading.value = false
      createError.value = null
      menuCategories.value.push(newMenuCategory)

      store.dispatch(
        'snackbar/showSnackbar',
        savedDefault,
      )
    }

    const onError = (error) => {
      console.error(error)
      createLoading.value = false
      createError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    const onSuccessGetCategories = (menuCategoryData) => {
      const newMenuCategory = new MenuCategoryModel(
        {
          name: categoryName,
          nameEN: categoryNameEN,
          isActive: active,
          orderOnDay: selectedDate,
          id:
            menuCategoryData.data().menuCategories.length > 0
              ? Math.max(...menuCategoryData.data().menuCategories.map(getIdRaw)) + 1
              : 1,
        },
        menuCategoryData.ref,
      )

      updateDoc(
        menuCategoryData.ref,
        {
          menuCategories: arrayUnion(newMenuCategory.toMap()),

          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      )
        .then(onSuccess(newMenuCategory))
        .catch(onError)
    }

    getDoc(menuCategoriesRefFun(userData.company))
      .then(onSuccessGetCategories)
      .catch(onError)
  }

  async function changeActivity(userData, data, isActive, selectedCategory) {
    menuCategoriesLoading.value = true

    try {
      const batch = writeBatch(firestore)

      data.forEach((item) => {
        batch.update(
          item.menuRef,
          {
            isActive: !isActive,
            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        )
      })

      await batch.commit()

      const getId = item => item.menuRef.id

      const moveMenusObjects = []
      const tmpMenus: any = isActive
        ? menus.value
        : menusInactive.value
      const menuRefs = data.map(getId)

      const filterAndUpdateMenuItems = (item) => {
        if (menuRefs.includes(item.menuRef.id)) {
          const tmpItem = item
          tmpItem.isActive = !isActive
          // @ts-expect-error
          moveMenusObjects.push(tmpItem)

          return false
        }

        return true
      }

      tmpMenus[selectedCategory] = tmpMenus[selectedCategory]
        .filter(filterAndUpdateMenuItems)

      if (isActive)
        menus.value = tmpMenus
      else
        menusInactive.value = tmpMenus

      if (
        (isActive && menusInactive.value && menusInactive.value[selectedCategory])
        || (!isActive && menus.value && menus.value[selectedCategory])
      ) {
        const tmpInactive: any = isActive
          ? menusInactive.value
          : menus.value
        tmpInactive[selectedCategory] = [
          ...tmpInactive[selectedCategory],
          ...moveMenusObjects,
        ]
        if (isActive)
          menusInactive.value = tmpInactive
        else
          menus.value = tmpInactive
      }

      menuCategoriesLoading.value = false
    }
    catch {
      menuCategoriesLoading.value = false
    }
  }

  function editCategory(userData, categoryName, categoryNameEN, categoryIndex, active, selectedDate) {
    createError.value = null
    createLoading.value = true

    const selectedMenuCategory = menuCategories.value[categoryIndex]

    const batch = writeBatch(firestore)

    batch.update(
      selectedMenuCategory.menuCategoryRef,
      {
        menuCategories: arrayRemove(selectedMenuCategory.toMap()),
        lastUpdateByUser: userData.reference,
        lastUpdateTime: serverTimestamp(),
      },
    )

    const menuToUpdate = selectedMenuCategory
    menuToUpdate.name = categoryName
    menuToUpdate.nameEN = categoryNameEN
    menuToUpdate.isActive = active
    menuToUpdate.orderOnDay = selectedDate

    batch.update(
      selectedMenuCategory.menuCategoryRef,
      {
        menuCategories: arrayUnion(menuToUpdate.toMap()),
        lastUpdateByUser: userData.reference,
        lastUpdateTime: serverTimestamp(),
      },
    )

    const onSuccess = () => {
      createLoading.value = false
      createError.value = null
      menuCategories.value[categoryIndex] = menuToUpdate
      store.dispatch(
        'snackbar/showSnackbar',
        savedDefault,
      )
    }

    const onError = (error) => {
      console.error(error)
      createLoading.value = false
      createError.value = error
      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    batch.commit().then(onSuccess).catch(onError)
  }

  function updateCategoryOrder(userData, data) {
    createError.value = null
    createLoading.value = true

    const onSuccess = () => {
      store.dispatch(
        'snackbar/showSnackbar',
        savedDefault,
      )
      createLoading.value = false
      createError.value = null
      menuCategories.value = data
      menus.value = null
      menus.value = null
      window.location.reload()
    }

    const onError = (error) => {
      console.error(error)
      createLoading.value = false
      createError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }
    if (menuCategoriesRef.value) {
      updateDoc(
        menuCategoriesRef.value,
        {
          menuCategories: data.map(item => item.toMap()),
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      )
        .then(onSuccess)
        .catch(onError)
    }
  }

  async function hideItem(reference, item) {
    createError.value = null
    createLoading.value = true

    try {
      await updateDoc(reference, { hidden: true })

      item.hidden = true
      createLoading.value = false
      createError.value = null

      store.dispatch(
        'snackbar/showSnackbar',
        { text: i18n.t('hideItem.success'), color: 'success' },
      )
    }
    catch (error) {
      createLoading.value = false
      createError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        { text: i18n.t('hideItem.failure'), color: 'error' },
      )
    }
  }

  return {
    menuCategoriesRef,
    menuCategories,
    menuCategoriesLoading,
    menus,
    menusInactive,
    menuOfDay,
    fetchMenuOfDayLoading,
    fetchMenuOfDayError,
    fetchLoading,
    fetchError,
    createLoading,
    createError,
    resetState,
    getMenuCategories,
    getMenu,
    getMenuFromDay,
    createNewFood,
    updateFood,
    createNewCategory,
    changeActivity,
    editCategory,
    updateCategoryOrder,
    hideItem,
  }
})
