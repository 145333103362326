import type { Unsubscribe } from 'firebase/firestore'
import type { Ref } from 'vue'
import type { ReportBugOrSuggestionModel } from '../models/ReportBugOrSuggestionModel'
import { addDoc, onSnapshot, serverTimestamp, updateDoc } from 'firebase/firestore'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import store from '.'
import {
  getReportBugOrSuggestionActive,
  getReportBugOrSuggestionActiveByCitiesActive,
  getReportBugOrSuggestionActiveByCitiesSolved,
  getReportBugOrSuggestionActiveByCitiesWaitingCustomerAnswer,
  getReportWithoutCities,
  reportBugOrSuggestionCollection,
} from '../firestoreWrappers'
import { isManagerOrDeliver, isOwner } from '../helpers/roles'
import { errorDefault, savedDefault } from '../helpers/snackbar'
import { mapReportBugOrSuggestion } from '../models/ReportBugOrSuggestionModel'

export const useReportBugOrSuggestion = defineStore('reportBugOrSuggestion', () => {
  const reportBugOrSuggestion: Ref<ReportBugOrSuggestionModel[] | null> = ref(null)
  const reportBugOrSuggestionSolved: Ref<ReportBugOrSuggestionModel[] | null> = ref(null)
  const reportBugOrSuggestionWaitingCustomerAnswer: Ref<ReportBugOrSuggestionModel[] | null> = ref(null)
  const reportBugOrSuggestionActive: Ref<ReportBugOrSuggestionModel[] | null> = ref(null)
  const reportBugOrSuggestionWithoutCities: Ref<ReportBugOrSuggestionModel[] | null> = ref(null)
  const unsubscribe: Ref<Unsubscribe | null> = ref(null)
  const unsubscribe2: Ref<Unsubscribe | null> = ref(null)
  const unsubscribe3: Ref<Unsubscribe | null> = ref(null)
  const unsubscribe4: Ref<Unsubscribe | null> = ref(null)
  const reportBugOrSuggestionLoading = ref(false)
  const reportBugOrSuggestionSuccess = ref(false)
  const reportBugOrSuggestionError: any = ref(null)

  function resetState() {
    if (unsubscribe.value)
      unsubscribe.value()

    if (unsubscribe2.value || unsubscribe3.value || unsubscribe4.value) {
      if (unsubscribe2.value)
        unsubscribe2.value()
      if (unsubscribe3.value)
        unsubscribe3.value()
      if (unsubscribe4.value)
        unsubscribe4.value()
      unsubscribe2.value = null
      unsubscribe3.value = null
      unsubscribe4.value = null
    }

    reportBugOrSuggestion.value = null
    reportBugOrSuggestionSolved.value = null
    reportBugOrSuggestionWaitingCustomerAnswer.value = null
    reportBugOrSuggestionActive.value = null
    reportBugOrSuggestionWithoutCities.value = null
    reportBugOrSuggestionLoading.value = false
    reportBugOrSuggestionSuccess.value = false
    reportBugOrSuggestionError.value = null
  }

  function getReportBugOrSuggestion(userData, user) {
    if (!userData.company)
      return

    reportBugOrSuggestionLoading.value = true
    reportBugOrSuggestionError.value = null

    if (unsubscribe.value) {
      unsubscribe.value()
      unsubscribe.value = null
    }
    if (unsubscribe2.value || unsubscribe3.value || unsubscribe4.value) {
      if (unsubscribe2.value)
        unsubscribe2.value()
      if (unsubscribe3.value)
        unsubscribe3.value()
      if (unsubscribe4.value)
        unsubscribe4.value()
      unsubscribe2.value = null
      unsubscribe3.value = null
      unsubscribe4.value = null
    }

    const onSuccess = ({ docs }) => {
      reportBugOrSuggestionLoading.value = false
      reportBugOrSuggestionError.value = null
      reportBugOrSuggestion.value = docs.map(mapReportBugOrSuggestion)
    }

    const onError = (error) => {
      reportBugOrSuggestionLoading.value = false
      reportBugOrSuggestionError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    if (isOwner(user.displayName) || isManagerOrDeliver(user.displayName)) {
      if (isOwner(user.displayName)) {
        const unsubscribeNew = onSnapshot(
          getReportBugOrSuggestionActive(userData.company),
          onSuccess,
          onError,
        )
        unsubscribe.value = unsubscribeNew
      }
      else {
        const unsubscribeNew = onSnapshot(
          getReportBugOrSuggestionActiveByCitiesActive(
            userData.company,
            userData.profile.cities,
          ),
          ({ docs }) => {
            reportBugOrSuggestionLoading.value = false
            reportBugOrSuggestionError.value = null

            reportBugOrSuggestionActive.value = (docs || []).map(mapReportBugOrSuggestion)
          },
          onError,

        )

        const unsubscribeNew2 = onSnapshot(
          getReportBugOrSuggestionActiveByCitiesSolved(
            userData.company,
            userData.profile.cities,
          ),
          ({ docs }) => {
            reportBugOrSuggestionLoading.value = false
            reportBugOrSuggestionError.value = null

            reportBugOrSuggestionSolved.value = (docs || []).map(mapReportBugOrSuggestion)
          },
          onError,

        )
        const unsubscribeNew4 = onSnapshot(
          getReportBugOrSuggestionActiveByCitiesWaitingCustomerAnswer(
            userData.company,
            userData.profile.cities,
          ),
          ({ docs }) => {
            reportBugOrSuggestionLoading.value = false
            reportBugOrSuggestionError.value = null

            reportBugOrSuggestionWaitingCustomerAnswer.value = (docs || []).map(mapReportBugOrSuggestion)
          },
          onError,

        )

        const unsubscribeNew3 = onSnapshot(
          getReportWithoutCities(userData.company),
          ({ docs }) => {
            reportBugOrSuggestionLoading.value = false
            reportBugOrSuggestionError.value = null

            reportBugOrSuggestionWithoutCities.value = (docs || []).map(mapReportBugOrSuggestion)
          },
          onError,
        )

        unsubscribe.value = unsubscribeNew
        unsubscribe2.value = unsubscribeNew2
        unsubscribe3.value = unsubscribeNew3
        unsubscribe4.value = unsubscribeNew4
      }
    }
    else {
      onError('Wrong site')
    }
  }
  function updateReportBugOrSuggestion(userData, item, status) {
    reportBugOrSuggestionLoading.value = true
    reportBugOrSuggestionError.value = null

    const onSuccess = () => {
      reportBugOrSuggestionLoading.value = false
      reportBugOrSuggestionError.value = null
    }

    const onError = (error) => {
      console.error(error)
      reportBugOrSuggestionLoading.value = false
      reportBugOrSuggestionError.value = error
      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    updateDoc(
      item.reference,
      {
        status,
        lastUpdateByUser: userData.reference,
        lastUpdateTime: serverTimestamp(),
      },
    ).then(onSuccess).catch(onError)
  }

  function sendCompanySuggestion(data) {
    reportBugOrSuggestionLoading.value = true
    reportBugOrSuggestionError.value = null

    const onSuccess = () => {
      reportBugOrSuggestionLoading.value = false
      reportBugOrSuggestionError.value = null
      store.dispatch(
        'snackbar/showSnackbar',
        savedDefault,
      )
    }

    const onError = (error) => {
      console.error(error)
      reportBugOrSuggestionLoading.value = false
      reportBugOrSuggestionError.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    addDoc(reportBugOrSuggestionCollection, data.toMap())
      .then(onSuccess)
      .catch(onError)
  }

  return {
    reportBugOrSuggestion,
    reportBugOrSuggestionSolved,
    reportBugOrSuggestionWaitingCustomerAnswer,
    reportBugOrSuggestionActive,
    reportBugOrSuggestionWithoutCities,
    unsubscribe,
    unsubscribe2,
    unsubscribe3,
    unsubscribe4,
    reportBugOrSuggestionLoading,
    reportBugOrSuggestionSuccess,
    reportBugOrSuggestionError,

    resetState,
    getReportBugOrSuggestion,
    updateReportBugOrSuggestion,
    sendCompanySuggestion,
  }
})
