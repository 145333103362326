<script setup lang="ts">
import { mdiClose, mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref } from 'vue'
import { useDrawerStore } from '~/store/drawer'
import { useI18n } from '../../useI18n'
import { useVuetify } from '../../useVuetify'

const drawerStore = useDrawerStore()
const { visibleLanding } = storeToRefs(drawerStore)

const isDark = ref(false)

const { isPhone, setTheme } = useVuetify()
const { setLocale, getLocale } = useI18n()

onMounted(() => {
  const mq = window.matchMedia('(prefers-color-scheme: dark)')

  const dark
    = localStorage.dark == null
      ? mq.matches
      : JSON.parse(localStorage.dark)
  isDark.value = dark
})

function toggleLang(key) {
  switch (key) {
    case 'PL':
      setLocale('pl')
      break
    case 'EN':
      setLocale('en')
      break
    case 'Рус':
      setLocale('ru')
      break
    case 'Укр':
      setLocale('ua')
      break

    default:
      setLocale('pl')
      break
  }
}

function toggleTheme2() {
  setTheme(!isDark.value)
  localStorage.dark = JSON.stringify(!isDark.value)
  isDark.value = !isDark.value
}

function toggleDrawer() {
  drawerStore.toggleDrawerLanding()
}

const mapLocale = computed(() => {
  switch (getLocale.value) {
    case 'pl':
      return 'PL'
    case 'en-US':
      return 'EN'
    case 'en-GB':
      return 'EN'
    case 'ru-RU':
      return 'Рус'
    case 'ua-UA':
      return 'Укр'

    default:
      return 'PL'
  }
})
</script>

<template>
  <div>
    <v-navigation-drawer
      v-show="isPhone"
      :value="visibleLanding"
      app
      right
      class="d-print-none max-height"
      width="100%"
    >
      <v-list>
        <v-list-item>
          <img
            height="36px"
            width="36px"
            class="rounded-corners"
            src="/landing/logo_icon.webp"
            rel="preload"
          >

          <h3
            class="pl-2"
            :dark="isDark"
          >
            {{ $t("App.title1") }}
          </h3>

          <v-spacer />

          <v-btn
            icon
            x-large
            @click="toggleDrawer"
          >
            <v-icon large>
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </v-list-item>

        <v-list-item to="/">
          {{ $t("LandingPage.start") }}
        </v-list-item>

        <v-divider />

        <v-list-item to="/catering">
          {{ $t("CateringView.title") }}
        </v-list-item>

        <v-divider />

        <v-list-item to="/forsuppliers">
          {{ $t("LandingPage.forCompanies") }}
        </v-list-item>

        <v-divider />

        <v-list-item to="/foremployees">
          {{ $t("ForEmployeesView.forEmployees") }}
        </v-list-item>

        <v-divider />

        <v-list-item to="/blog">
          {{ $t("LandingPage.blog") }}
        </v-list-item>

        <v-divider />

        <v-list-item to="/contact">
          {{ $t("LandingPage.contact") }}
        </v-list-item>

        <v-divider />

        <v-list-item to="/login">
          {{ $t("LandingPage.login") }}
        </v-list-item>

        <v-divider />

        <v-list-item @click="toggleTheme2">
          {{ $t("darkTheme") }}
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      color="black"
    >
      <router-link to="/">
        <v-row dense>
          <img
            width="36px"
            height="36px"
            class="rounded-corners"
            src="/landing/logo_icon.webp"
            rel="preload"
          >

          <h3 class="d-lg-flex d-md-none d-sm-none pl-2 pt-1 white--text">
            {{ $t("App.title1") }}
          </h3>
        </v-row>
      </router-link>

      <v-spacer />

      <v-btn
        class="d-none d-sm-flex mx-1 px-1"
        to="/"
        dark
        color="black"
      >
        <span class="white--text">
          {{ $t("LandingPage.start") }}
        </span>
      </v-btn>

      <v-btn
        class="d-none d-sm-flex mx-1 px-1"
        to="/catering"
        dark
        color="black"
      >
        <span class="white--text">
          {{ $t("CateringView.title") }}
        </span>
      </v-btn>

      <v-btn
        class="d-none d-sm-flex mx-1 px-1"
        to="/forsuppliers"
        dark
        color="black"
      >
        <span class="white--text">
          {{ $t("LandingPage.forCompanies") }}
        </span>
      </v-btn>

      <v-btn
        class="d-md-flex d-none mx-1 px-1"
        to="/foremployees"
        dark
        color="black"
      >
        <span class="white--text">
          {{ $t("ForEmployeesView.forEmployees") }}
        </span>
      </v-btn>

      <v-btn
        class="d-md-none d-none d-sm-flex mx-1 px-1"
        to="/foremployees"
        dark
        color="black"
      >
        <span class="white--text">
          {{ $t("ForEmployeesView.forEmployeesSM") }}
        </span>
      </v-btn>

      <v-btn
        class="d-none d-sm-flex mx-1 px-1"
        to="/blog"
        dark
        color="black"
      >
        <span class="white--text">
          {{ $t("LandingPage.blog") }}
        </span>
      </v-btn>

      <v-btn
        class="d-none d-sm-flex mx-1 px-1"
        to="/contact"
        dark
        color="black"
      >
        <span class="white--text">
          {{ $t("LandingPage.contact") }}
        </span>
      </v-btn>

      <v-spacer />

      <v-select
        id="lang"
        :items="[
          'PL',
          'EN',
          'Рус',
          'Укр',
        ]"
        dense
        :value="mapLocale"
        class="max-width"
        full-width
        hide-details
        flat
        width="10px"
        dark
        @change="toggleLang"
      />

      <v-btn
        class="d-none d-sm-flex mx-0 px-1"
        to="/login"
        color="black"
      >
        <span class="white--text">
          {{ $t("LandingPage.login") }}
        </span>
      </v-btn>

      <ToggleDrawerLanding
        dark
        class="d-flex d-sm-none"
      />
    </v-app-bar>

    <v-btn
      v-if="!isPhone"
      class="v-btn-align"
      fab
      :dark="!isDark"
      @click="toggleTheme2"
    >
      <v-icon>
        {{ isDark
          ? mdiWeatherSunny
          : mdiWeatherNight }}
      </v-icon>
    </v-btn>
  </div>
</template>

<style scoped>
.v-btn {
  text-transform: capitalize;
}
a {
  text-decoration: none;
}
.v-btn-align {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}
.max-width {
  max-width: 65px;
}
.max-height {
  max-height: 460px;
}
.rounded-corners {
  border-radius: 50%;
}
</style>
