import type { DocumentReference, FieldValue } from 'firebase/firestore'
import i18n from '~/i18n'
import CounterModel from './CounterModel'
import MacroModel from './MacroModel'
import { mapMenuCategories, MenuCategoryModel } from './MenuCategoryModel'

export { mapMenuCategories, MenuCategoryModel }

export function defaultMacros() {
  return [
    new MacroModel({
      name: i18n.t('Macro.Energy'),
      nameEN: i18n.t('Macro.EnergyEN'),
      units: 'kcal',
      value: 0,
    }),
    new MacroModel({
      name: i18n.t('Macro.Fats'),
      nameEN: i18n.t('Macro.FatsEN'),
      units: 'g',
      value: 0,
    }),
    new MacroModel({
      name: i18n.t('Macro.FattyAcids'),
      nameEN: i18n.t('Macro.FattyAcidsEN'),
      units: 'g',
      value: 0,
    }),
    new MacroModel({
      name: i18n.t('Macro.Carbohydrates'),
      nameEN: i18n.t('Macro.CarbohydratesEN'),
      units: 'g',
      value: 0,
    }),
    new MacroModel({
      name: i18n.t('Macro.Monosaccharides'),
      nameEN: i18n.t('Macro.MonosaccharidesEN'),
      units: 'g',
      value: 0,
    }),
    new MacroModel({
      name: i18n.t('Macro.Protein'),
      nameEN: i18n.t('Macro.ProteinEN'),
      units: 'g',
      value: 0,
    }),
    new MacroModel({
      name: i18n.t('Macro.Salt'),
      nameEN: i18n.t('Macro.SaltEN'),
      units: 'g',
      value: 0,
    }),
  ]
}

const mapMacro = macro => new MacroModel(macro)
const toMapMacro = macro => macro.toMap()

export class MenuModel {
  description: string | null
  descriptionEN: string | null
  image: string | null
  isActive: boolean
  name: string | null
  nameEN: string | null
  productCounter: CounterModel | null
  macro: [] | null
  activeDays: string[] | null
  allergens: string[] | null
  price: string | null
  likes: string | null
  foodSort: string | null
  favourites: string | null
  imagePath: string | null
  companyRef: string | null
  categoryID: string | null
  imageHigh: string | null
  imageHighPath: string | null
  weight: string | null
  barCode: string | null
  isSpicy: boolean | null
  hidden: boolean
  createdByUser: DocumentReference | null
  lastUpdateTime: FieldValue | null
  lastUpdateByUser: DocumentReference | null
  menuRef: string | null

  constructor(data, menuRef) {
    this.foodSort = data.foodSort || null
    this.description = data.description || null
    this.descriptionEN = data.descriptionEN || null
    this.image = data.image || null
    this.isActive = data.isActive || false
    this.name = data.name || null
    this.nameEN = data.nameEN || null
    this.productCounter = data.productCounter
      ? new CounterModel(data.productCounter)
      : null
    this.macro = data.macro
      ? data.macro.map(mapMacro)
      : []
    this.activeDays = data.activeDays || []
    this.allergens = data.allergens || null
    this.price = data.price || null
    this.weight = data.weight || null
    this.likes = data.likes || []
    this.favourites = data.favourites || []
    this.imagePath = data.imagePath || null
    this.imageHigh = data.imageHigh || null
    this.imageHighPath = data.imageHighPath || null
    this.companyRef = data.companyRef || null
    this.categoryID = data.categoryID || null
    this.barCode = data.barCode || null
    this.isSpicy = data.isSpicy || false
    this.hidden = data.hidden || false

    this.createdByUser = data.createdByUser || null
    this.lastUpdateByUser = data.lastUpdateByUser || null
    this.lastUpdateTime = data.lastUpdateTime || null

    this.menuRef = menuRef
  }

  toMap() {
    return {
      description: this.description?.trim() || null,
      descriptionEN: this.descriptionEN?.trim() || null,
      image: this.image,
      isActive: this.isActive,
      name: this.name?.trim() || null,
      nameEN: this.nameEN?.trim() || null,
      productCounter: this.productCounter
        ? this.productCounter.toMap()
        : null,
      macro: this.macro?.map(toMapMacro),
      activeDays: this.activeDays,
      allergens: this.allergens,
      price: this.price,
      likes: this.likes,
      foodSort: this.foodSort,
      favourites: this.favourites,
      imagePath: this.imagePath,
      companyRef: this.companyRef,
      categoryID: this.categoryID,
      imageHigh: this.imageHigh,
      imageHighPath: this.imageHighPath,
      weight: this.weight,
      barCode: this.barCode,
      isSpicy: this.isSpicy,
      hidden: this.hidden,

      lastUpdateTime: this.lastUpdateTime || null,
      lastUpdateByUser: this.lastUpdateByUser || null,
    }
  }

  toUpdateMap() {
    return {
      description: this.description?.trim() || null,
      descriptionEN: this.descriptionEN?.trim() || null,
      image: this.image,
      imagePath: this.imagePath,
      imageHigh: this.imageHigh,
      imageHighPath: this.imageHighPath,
      isActive: this.isActive,
      foodSort: this.foodSort,
      name: this.name?.trim() || null,
      nameEN: this.nameEN?.trim() || null,
      macro: this.macro?.map(toMapMacro),
      productCounter: this.productCounter
        ? this.productCounter.toMap()
        : null,
      activeDays: this.activeDays,
      allergens: this.allergens,
      price: this.price,
      weight: this.weight,
      barCode: this.barCode,
      isSpicy: this.isSpicy,
      hidden: this.hidden,

      lastUpdateTime: this.lastUpdateTime || null,
      lastUpdateByUser: this.lastUpdateByUser || null,
    }
  }
}

export function mapMenuModel(menuData) {
  return new MenuModel(
    menuData.data(),
    menuData.ref,
  )
}
