import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '../firebaseCore'
import SignOutGuard from '../guards/signOutGuard'

Vue.use(VueRouter)

const metaModerator = {
  requiresAuth: true,
  moderator: true,
}

const metaCompany = {
  requiresAuth: true,
  owner: true,
  deliver: true,
  manager: true,
}

const metaCustomerManager = {
  requiresAuth: true,
  customer: true,
}

export const routes = [
  {
    path: '/sign-out',
    name: 'sign-out',
    beforeEnter: SignOutGuard,
  },
  {
    path: '*',
    component: () => import('../views/error/TheError404.vue'),
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/guest/LandingView.vue'),
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/guest/BlogView.vue'),
  },
  {
    path: '/blog/who-is-mr-sandwich',
    name: 'ArticleWhoIsMrSandwich',
    component: () => import('../views/guest/ArticleWhoIsMrSandwich1.vue'),
  },
  {
    path: '/blog/ordering-food-for-home',
    name: 'ArticleOrderingFoodForHome',
    component: () => import('../views/guest/ArticleOrderingFoodForHome2.vue'),
  },
  {
    path: '/blog/how-breakfasts-are-produced',
    name: 'ArticleHowBreakfastsAreProduced',
    component: () => import('../views/guest/ArticleHowBreakfastsAreProduced3.vue'),
  },
  {
    path: '/blog/why-choose-our-breakfasts',
    name: 'ArticleWhyChooseOurBreakfasts',
    component: () => import('../views/guest/ArticleWhyChooseOurBreakfasts4.vue'),
  },
  {
    path: '/forsuppliers',
    name: 'forsuppliers',
    component: () => import('../views/guest/ForSuppliersView.vue'),
  },
  {
    path: '/catering',
    name: 'catering',
    component: () => import('../views/guest/CateringView.vue'),
  },
  {
    path: '/foremployees',
    name: 'foremployees',
    component: () => import('../views/guest/ForEmployeesView.vue'),
  },
  {
    path: '/m',
    name: 'mobile-redirect',
    component: () => import('../views/guest/MobileRedirect.vue'),
  },
  {
    path: '/error-deliver',
    name: 'error-deliver',
    component: () => import('../views/guest/ErrorDeliver.vue'),
  },
  {
    path: '/error-active-account',
    name: 'error-active-account',
    component: () => import('../views/guest/ErrorActiveAccount.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/guest/LoginView.vue'),
  },
  {
    path: '/login-deliver',
    name: 'loginDeliver',
    component: () => import('../views/guest/LoginDeliverView.vue'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import('../views/guest/ResetPasswordView.vue'),
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/guest/ContactView.vue'),
  },
  {
    path: '/auth',
    name: 'authView',
    component: () => import('../views/guest/AuthView.vue'),
  },
  {
    path: '/dofinansowania',
    name: 'fundingView',
    component: () => import('../views/guest/FundingView.vue'),
  },
  {
    path: '/cookies',
    name: 'cookies',
    component: () => import('../views/guest/CookiesView.vue'),
  },
  {
    path: '/deliver-instruction',
    name: 'deliver-instruction',
    component: () => import('../views/guest/InstructionView.vue'),
  },
  {
    path: '/challenge-terms',
    name: 'challenge-terms',
    component: () => import('../views/guest/ChallengeTermsView.vue'),
  },
  {
    path: '/coupons-terms',
    name: 'coupons-terms',
    component: () => import('../views/guest/PromoCodeTermsView.vue'),
  },
  {
    path: '/referral-program-rules',
    name: 'referral-program-rules',
    component: () => import('../views/guest/RecommendationProgramView.vue'),
  },
  {
    path: '/posilek-profilaktyczny-dla-firm-warszawa',
    name: 'posilek-profilaktyczny-warszawa',
    component: () => import('../views/guest/PreventiveMealWarsawView.vue'),
  },
  {
    path: '/posilek-profilaktyczny-dla-firm-lodz',
    name: 'posilek-profilaktyczny-lodz',
    component: () => import('../views/guest/PreventiveMealLodzView.vue'),
  },
  {
    path: '/posilek-profilaktyczny-dla-firm-wroclaw',
    name: 'posilek-profilaktyczny-wroclaw',
    component: () => import('../views/guest/PreventiveMealWroclawView.vue'),
  },
  {
    path: '/posilek-profilaktyczny-dla-firm-krakow',
    name: 'posilek-profilaktyczny-krakow',
    component: () => import('../views/guest/PreventiveMealKrakowView.vue'),
  },
  {
    path: '/posilek-profilaktyczny-dla-firm-poznan',
    name: 'posilek-profilaktyczny-poznan',
    component: () => import('../views/guest/PreventiveMealPoznanView.vue'),
  },
  {
    path: '/posilek-profilaktyczny-dla-firm-katowice',
    name: 'posilek-profilaktyczny-katowice',
    component: () => import('../views/guest/PreventiveMealKatowiceView.vue'),
  },
  {
    path: '/posilek-profilaktyczny-dla-firm-czestochowa',
    name: 'posilek-profilaktyczny-czestochowa',
    component: () => import('../views/guest/PreventiveMealCzestochowaView.vue'),
  },
  {
    path: '/posilek-profilaktyczny-dla-firm-gdansk',
    name: 'posilek-profilaktyczny-gdansk',
    component: () => import('../views/guest/PreventiveMealGdanskView.vue'),
  },
  {
    path: '/posilek-profilaktyczny-dla-firm-sopot',
    name: 'posilek-profilaktyczny-sopot',
    component: () => import('../views/guest/PreventiveMealSopotView.vue'),
  },
  {
    path: '/posilek-profilaktyczny-dla-firm-gdynia',
    name: 'posilek-profilaktyczny-gdynia',
    component: () => import('../views/guest/PreventiveMealGdyniaView.vue'),
  },
  {
    path: '/sniadanie-warszawa',
    name: 'sniadanie-warszawa',
    component: () => import('../views/guest/BreakfastView.vue'),
  },
  {
    path: '/sniadanie-lodz',
    name: 'sniadanie-lodz',
    component: () => import('../views/guest/BreakfastView.vue'),
  },
  {
    path: '/sniadanie-wroclaw',
    name: 'sniadanie-wroclaw',
    component: () => import('../views/guest/BreakfastView.vue'),
  },
  {
    path: '/sniadanie-krakow',
    name: 'sniadanie-krakow',
    component: () => import('../views/guest/BreakfastView.vue'),
  },
  {
    path: '/sniadanie-poznan',
    name: 'sniadanie-poznan',
    component: () => import('../views/guest/BreakfastView.vue'),
  },
  {
    path: '/sniadanie-katowice',
    name: 'sniadanie-katowice',
    component: () => import('../views/guest/BreakfastView.vue'),
  },
  {
    path: '/sniadanie-czestochowa',
    name: 'sniadanie-czestochowa',
    component: () => import('../views/guest/BreakfastView.vue'),
  },
  {
    path: '/sniadanie-gdansk',
    name: 'sniadanie-gdansk',
    component: () => import('../views/guest/BreakfastView.vue'),
  },
  {
    path: '/sniadanie-sopot',
    name: 'sniadanie-sopot',
    component: () => import('../views/guest/BreakfastView.vue'),
  },
  {
    path: '/sniadanie-gdynia',
    name: 'sniadanie-gdynia',
    component: () => import('../views/guest/BreakfastView.vue'),
  },
  {
    path: '/moderator',
    name: 'moderator',
    component: () => import('../views/moderator/ModeratorView.vue'),
    meta: metaModerator,
  },
  {
    path: '/messages',
    name: 'messages',
    component: () => import('../views/moderator/MessagesView.vue'),
    meta: metaModerator,
  },
  {
    path: '/companies',
    name: 'companies',
    component: () => import('../views/moderator/CompaniesView.vue'),
    meta: metaModerator,
  },
  {
    path: '/payment-last-working-day',
    name: 'payment-last-working-day',
    component: () => import('../views/company/PaymentsLastWorkingDayView.vue'),
    meta: metaCompany,
  },
  {
    path: '/company',
    name: 'company',
    component: () => import('../views/company/CompanyView.vue'),
    meta: {
      requiresAuth: true,
      owner: true,
    },
  },
  {
    path: '/catering-orders',
    name: 'catering-orders',
    component: () => import('../views/company/CateringOrdersView.vue'),
    meta: metaCompany,
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/company/NewsView.vue'),
    meta: metaCompany,
  },
  {
    path: '/new-users',
    name: 'new-users',
    component: () => import('../views/company/NewUsersView.vue'),
    meta: metaCompany,
  },
  {
    path: '/coupons',
    name: 'coupons',
    component: () => import('../views/company/CouponsView.vue'),
    meta: metaCompany,
  },
  {
    path: '/promo-code-statistics',
    name: 'promo-code-statistics',
    component: () => import('../views/company/PromoCodeStatisticsView.vue'),
    meta: metaCompany,
  },
  {
    path: '/promo-code-usage',
    name: 'promo-code-usage',
    component: () => import('../views/company/PromoCodeUsageView.vue'),
    meta: metaCompany,
  },
  {
    path: '/routes',
    name: 'routes',
    component: () => import('../views/company/RoutesView.vue'),
    meta: metaCompany,
  },
  {
    path: '/delivers-sells',
    name: 'deliversSells',
    component: () => import('../views/company/DeliversSellsView.vue'),
    meta: metaCompany,
  },
  {
    path: '/suppliers',
    name: 'suppliers',
    component: () => import('../views/company/SuppliersPage.vue'),
    meta: metaCompany,
  },
  {
    path: '/suppliers-map',
    name: 'suppliersMap',
    component: () => import('../views/company/SuppliersMapView.vue'),
    meta: metaCompany,
  },
  {
    path: '/customers-map',
    name: 'customersMap',
    component: () => import('../views/company/CustomersMapView.vue'),
    meta: metaCompany,
  },
  {
    path: '/employees',
    name: 'employees',
    component: () => import('../views/company/EmployeesView.vue'),
    meta: metaCompany,
  },
  {
    path: '/employeesRanking',
    name: 'employeesRanking',
    component: () => import('../views/company/EmployeesRankingView.vue'),
    meta: metaCompany,
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('../views/company/OrdersView.vue'),
    meta: metaCompany,
  },
  {
    path: '/onlineTransactionReports',
    name: 'onlineTransactionReports',
    component: () => import('../views/company/OnlineTransactionReports.vue'),
    meta: metaCompany,
  },
  {
    path: '/ordersStatistic',
    name: 'ordersStatisticView',
    component: () => import('../views/company/OrdersStatisticView.vue'),
    meta: metaCompany,
  },
  {
    path: '/productsStatistic',
    name: 'productsStatisticView',
    component: () => import('../views/company/ProductsStatisticView.vue'),
    meta: metaCompany,
  },
  {
    path: '/promotionsReports',
    name: 'promotionsReports',
    component: () => import('../views/company/PromotionsReports.vue'),
    meta: metaCompany,
  },
  {
    path: '/stats',
    name: 'statsView',
    component: () => import('../views/company/StatsView.vue'),
    meta: metaCompany,
  },
  {
    path: '/menu',
    name: 'menu',
    component: () => import('../views/company/MenuView.vue'),
    meta: metaCompany,
  },
  {
    path: '/reportBugOrSuggestion',
    name: 'reportBugOrSuggestion',
    component: () => import('../views/company/ReportBugOrSuggestionView.vue'),
    meta: metaCompany,
  },
  {
    path: '/missingCompanies',
    name: 'missingCompanies',
    component: () => import('../views/company/MissingCompaniesView.vue'),
    meta: metaCompany,
  },
  {
    path: '/subsidy',
    name: 'subsidy',
    component: () => import('../views/company/SubsidyView.vue'),
    meta: metaCompany,
  },
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('../views/company/InvoicesView.vue'),
    meta: metaCompany,
  },
  {
    path: '/suppliersSubsidy',
    name: 'suppliersSubsidy',
    component: () => import('../views/company/SuppliersSubsidyView.vue'),
    meta: metaCompany,
  },
  {
    path: '/customersSubsidy',
    name: 'customersSubsidy',
    component: () => import('../views/manager/CustomersSubsidyView.vue'),
    meta: metaCustomerManager,
  },
  {
    path: '/subsidyBudget',
    name: 'subsidyBudget',
    component: () => import('../views/manager/ManagerRoutes.vue'),
    meta: metaCustomerManager,
  },
  {
    path: '/subsidyReport',
    name: 'subsidyReport',
    component: () => import('../views/manager/SubsidyReportView.vue'),
    meta: metaCustomerManager,
  },
  {
    path: '/information-clause',
    name: 'information-clause',
    component: () => import('../views/guest/InformationClauseView.vue'),
  },
  {
    path: '/rodoClause',
    name: 'rodoClause',
    component: () => import('../views/guest/InformationClauseView.vue'),
  },
  {
    path: '/regulation',
    name: 'regulation',
    component: () => import('../views/guest/RegulationView.vue'),
  },
  {
    path: '/regulation/:id',
    name: 'regulation-company',
    component: () => import('../views/guest/RegulationCompanyView.vue'),
  },
  {
    path: '/regulation-old',
    name: 'regulation-old',
    component: () => import('../views/guest/RegulationOldView.vue'),
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('../views/guest/PrivacyView.vue'),
  },
  {
    path: '/privacy/:id',
    name: 'privacy-company',
    component: () => import('../views/guest/PrivacyCompanyView.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy1',
    component: () => import('../views/guest/PrivacyView.vue'),
  },
  {
    path: '/privacy-policy/:id',
    name: 'privacy-company2',
    component: () => import('../views/guest/PrivacyCompanyView.vue'),
  },
  {
    path: '/cookies2',
    name: 'cookies2',
    component: () => import('../views/guest/CookiesView2.vue'),
  },
  {
    path: '/inviteFriends',
    name: 'inviteFriends',
    component: () => import('../views/guest/InviteFriendsView.vue'),
  },
  {
    path: '/redirectToApp/:companyRef',
    name: 'redirectToApp',
    component: () => import('../views/guest/RedirectToAppView.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  // @ts-expect-error
  base: import.meta.env.BASE_URL,
  routes,
})

router.beforeEach((to, _from, next) => {
  const requiresAuth = to.matched.some(xxx => xxx.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  }
  else {
    // @ts-expect-error
    const requiresAuthResult = to.matched.some(xxx => (xxx.meta.requiresAuth && xxx.meta[auth?.currentUser?.displayName])
      || !xxx.meta.requiresAuth)

    if (auth.currentUser && to.path === '/')
      next('/routes')

    if (requiresAuthResult)
      next()
    else
      next('/sign-out')
  }
})

export default router
