import type { Ref } from 'vue'
import type { CompanyModel } from '../models/CompanyModel'
import { getDocs, query, serverTimestamp, updateDoc, where, writeBatch } from 'firebase/firestore'
import { getDownloadURL, listAll, ref as refFirebase, uploadBytes } from 'firebase/storage'
import { defineStore } from 'pinia'
import { ref } from 'vue'
import store from '.'
import { collectionCompanies } from '../firebase/firestoreCompanies'
import { firestore, storage } from '../firebaseCore'
import { collectionOwners } from '../firestoreWrappers'
import { errorDefault, savedDefault } from '../helpers/snackbar'
import { mapCompany } from '../models/CompanyModel'
import { mapOwners } from '../models/ManagerModel'

const mapPromises = item => getDownloadURL(item)
function mapFunc(data) {
  return (item, index) => ({
    name: item.name,
    url: data[index],
  })
}

function mapByOwner(owner) {
  return (ownerData) => {
    if (ownerData.reference.id === owner.reference.id)
      ownerData.loading = true

    return ownerData
  }
}

function mapByOwner2(owner) {
  return (ownerData) => {
    if (ownerData.reference.id === owner.reference.id) {
      ownerData.isOwner = !owner?.isOwner
      delete ownerData.loading
    }

    return ownerData
  }
}

function mapByOwner3(owner) {
  return (ownerData) => {
    if (ownerData.reference.id === owner.reference.id)
      delete ownerData.loading

    return ownerData
  }
}

function mapByCompany(company, agreement) {
  return (companyData) => {
    if (companyData.reference.id === company.reference.id)
      companyData.agreement = agreement

    return companyData
  }
}
export const useCompanies = defineStore('companies', () => {
  const companies: Ref<CompanyModel[] | null> = ref(null)
  const agreements: any = ref(null)
  const loadingAgreements = ref(false)
  const errorAgreements = ref(null)
  const owners: any = ref(null)
  const loadingOwners = ref(false)
  const errorOwners = ref(null)

  function resetState() {
    companies.value = null
    agreements.value = null
    owners.value = null
    loadingAgreements.value = false
    errorAgreements.value = null
    loadingOwners.value = false
    errorOwners.value = null
  }

  function getCompanies() {
    store.dispatch('shared/init')

    const onSuccess = ([{ docs }]) => {
      companies.value = docs.map(mapCompany)

      store.dispatch('shared/success')
    }

    const onError = (error) => {
      console.error(error)
      store.dispatch(
        'shared/error',
        error,
      )
      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    Promise.all([getDocs(collectionCompanies)])

      .then(onSuccess)
      .catch(onError)
  }

  function getOwners(company) {
    loadingOwners.value = true
    errorOwners.value = null

    const onSuccess = ({ docs }) => {
      loadingOwners.value = false
      owners.value = docs.map(mapOwners)
    }

    const onError = (error) => {
      console.error(error)
      loadingOwners.value = false
      errorOwners.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    getDocs(query(
      collectionOwners,
      where(
        'company',
        '==',
        company.reference,
      ),
    ))
      .then(onSuccess)
      .catch(onError)
  }

  function getAgreements(company) {
    loadingAgreements.value = true
    errorAgreements.value = null

    const onError = (error) => {
      console.error(error)
      loadingAgreements.value = false
      errorAgreements.value = error

      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    const onSuccess = ({ items }) => {
      Promise.all(items.map(mapPromises))
        .then((data) => {
          loadingAgreements.value = false
          agreements.value = items.map(mapFunc(data))
        })
        .catch(onError)
    }

    const path = `${company.reference.id}/docs/`

    listAll(refFirebase(
      storage,
      path,
    ))
      .then(onSuccess)
      .catch(onError)
  }

  function updateOwnerStatus(userData, owner) {
    const newOwnerData = {
      isOwner: !owner?.isOwner,
      lastUpdateByUser: userData.reference,
      lastUpdateTime: serverTimestamp(),
    }

    const byId = company => company?.reference?.id === owner.company.id

    const ownerCompany = companies.value?.find(byId)
    const acceptedByObject = ownerCompany?.agreement?.acceptedBy || {}

    if (!owner?.isOwner)
      acceptedByObject[owner.reference.id] = false
    else
      delete acceptedByObject[owner.reference.id]

    const newCompanyAgreement = {
      agreement: {
        ...ownerCompany?.agreement || {},
        acceptedBy: acceptedByObject,
      },
    }

    owners.value = owners.value?.map(mapByOwner(owner))

    const onError = (error) => {
      console.error(error)
      owners.value = owners.value?.map(mapByOwner3(owner))
      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    const onSuccess = () => {
      owners.value = owners.value.map(mapByOwner2(owner))
      companies.value = companies.value?.map(mapByCompany(ownerCompany, newCompanyAgreement.agreement)) || []
    }

    const newCompanyAgreementData = {
      ...newCompanyAgreement,
      lastUpdateByUser: userData.reference,
      lastUpdateTime: serverTimestamp(),
    }

    const batch = writeBatch(firestore)

    batch
      .update(
        owner.reference,
        newOwnerData,
      )
      .update(
        // @ts-expect-error todo
        ownerCompany.reference,
        newCompanyAgreementData,
      )
      .commit()
      .then(onSuccess)
      .catch(onError)
  }

  function uploadAgreement(selectedFile, company) {
    const activeFile = `${new Date().toISOString()}${selectedFile.name}`
    const path = `${company.reference.id}/docs/${activeFile}`

    const onSuccess = (result) => {
      getDownloadURL(result.ref).then((url) => {
        const agreement = {
          name: result.metadata.name,
          url,
        }

        agreements.value = [
          ...agreements.value || [],
          agreement,
        ]
      })
    }

    const onError = (error) => {
      console.error(error)
      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    uploadBytes(
      refFirebase(
        storage,
        path,
      ),
      selectedFile,
    )
      .then(onSuccess)
      .catch(onError)
  }

  function updateCompanyAgreement(userData, agreement, company) {
    const onSuccess = () => {
      companies.value = companies.value?.map(mapByCompany(company, {
        ...(company?.agreement || {}),
        ...(agreement || {}),
      })) || []
      store.dispatch(
        'snackbar/showSnackbar',
        savedDefault,
      )
    }

    const onError = (error) => {
      console.error(error)
      store.dispatch(
        'snackbar/showSnackbar',
        errorDefault(error),
      )
    }

    updateDoc(
      company.reference,
      {
        agreement: {
          ...company?.agreement || {},
          ...agreement,
        },
        lastUpdateByUser: userData.reference,
        lastUpdateTime: serverTimestamp(),

      },
    )
      .then(onSuccess)
      .catch(onError)
  }

  return {
    companies,
    agreements,
    loadingAgreements,
    errorAgreements,
    owners,
    loadingOwners,
    errorOwners,
    resetState,
    getCompanies,
    getOwners,
    getAgreements,
    updateOwnerStatus,
    uploadAgreement,
    updateCompanyAgreement,
  }
})
