import { applyActionCode, confirmPasswordReset, signInWithEmailAndPassword, signOut, updateProfile, verifyPasswordResetCode } from 'firebase/auth'
import { doc, getDoc } from 'firebase/firestore'
import { useNewsStore } from '~/store/news'
import store, { } from '.'
import { auth } from '../firebaseCore'
import {
  collectionUsers,
  getCustomer,
  getDeliver,
  getManager,
  getModerator,
  getOwner,
} from '../firestoreWrappers'
import { COMPNAY_ROLES, isDeliver, ROLE } from '../helpers/roles'
import { mapToModel } from '../models/ManagerModel'
import router from '../router'
import { useCompanies } from './companies'
import { useCoupons } from './coupons'
import { useInvoices } from './invoices'
import { useManagerRoutes } from './managerRoutes'
import { useMenus } from './menus'
import { useMessages } from './messages'
import { useMissingCompanies } from './missingCompanies'
import { useOnlineTransactionReports } from './onlineTransactionReports'
import { useOrdersStatistic } from './ordersStatistic'
import { useReportBugOrSuggestion } from './reportBugOrSuggestion'
import { useSuppliersSubsidyStore } from './suppliersSubsidy'

export function initialState() {
  return {
    user: null,
    userData: null,
    error: null,
    loading: false,
  }
}

function getRoute(role) {
  switch (role) {
    case ROLE.DELIVER:
      return '/routes'
    case ROLE.OWNER:
      return '/company'
    case ROLE.MANAGER:
      return '/routes'
    case ROLE.MODERATOR:
      return '/moderator'
    default:
      return '/sign-out'
  }
}

function getRouteUser(role) {
  switch (role) {
    case ROLE.MANAGER:
      return '/subsidyBudget'
    default:
      return '/sign-out'
  }
}

const getUser = uid => getDoc(doc(collectionUsers, uid))

export default {
  namespaced: true,
  state: {
    user: null,
    userData: null,
    error: null,
    loading: false,
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE')
    },
    checkUserData({ dispatch }, user) {
      if (COMPNAY_ROLES.includes(user?.displayName)) {
        const logIn = (userData, currentUserData) => {
          if (currentUserData.data()?.isActive) {
            dispatch(
              'logIn',
              {
                user: userData,
                currentUserData,
              },
            )
          }
          else {
            router.push('error-active-account')
            dispatch('signOut')
          }
        }

        const onSuccessDeliver = userData => (deliver) => {
          if (!deliver.exists() || isDeliver(deliver.data().role)) {
            router.push('error-deliver')
            dispatch('signOut')
          }
          else {
            logIn(
              userData,
              deliver,
            )
          }
        }

        const onSuccess = userData => deliver => logIn(
          userData,
          deliver,
        )

        let request
        switch (user.displayName) {
          case ROLE.OWNER:
            request = getOwner(user.uid)
            break
          case ROLE.GET_DELIVER:
            request = getDeliver(user.uid)
            break
          case ROLE.DELIVER:
            request = getDeliver(user.uid)
            break
          case ROLE.MANAGER:
            request = getManager(user.uid)
            break
          case ROLE.MODERATOR:
            request = getModerator(user.uid)
            break
          case ROLE.CUSTOMER:
            request = getCustomer(user.uid)
            break
          default:
            dispatch('signOut')
            break
        }

        const onSuccess2 = (userData) => {
          if (user?.displayName === ROLE.DELIVER)
            onSuccessDeliver(user)(userData)
          else
            onSuccess(user)(userData)
        }

        request.then(onSuccess2).catch(() => {
          router.push('error-active-account')
          dispatch('signOut')
        })
      }
      else {
        dispatch('signOut')
      }
    },
    loginAndSetDeliverDisplayName({ commit, dispatch }, { email, password }) {
      commit('PASSWORD_LOGIN_REQUEST')

      const onSuccess = ({ user }) => {
        commit('PASSWORD_LOGIN_SUCCESS')

        updateProfile(user, {
          displayName: 'deliver',
        })

        dispatch('signOut')

        router.push('/login')
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'PASSWORD_LOGIN_FAILURE',
          error,
        )
      }

      signInWithEmailAndPassword(
        auth,
        email,
        password,
      )
        .then(onSuccess)
        .catch(onError)
    },
    loginWithPassword({ commit }, { email, password }) {
      commit('PASSWORD_LOGIN_REQUEST')

      const onSuccess = ({ user }) => {
        commit('PASSWORD_LOGIN_SUCCESS')

        // update display name
        // user.updateProfile({
        //   displayName: 'customer',
        // })

        if (user.displayName == 'customer')
          getUser(user.uid).then(response => router.push(getRouteUser(response.data()?.role)))

        else
          router.push(getRoute(user.displayName))
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'PASSWORD_LOGIN_FAILURE',
          error,
        )
      }

      signInWithEmailAndPassword(
        auth,
        email,
        password,
      )
        .then(onSuccess)
        .catch(onError)
    },
    logIn({ commit, dispatch }, { user, currentUserData }) {
      const userData = mapToModel(currentUserData, user.displayName)

      if (!currentUserData.data()?.isActive) {
        router.push('error-active-account')
        dispatch('signOut')

        return
      }

      // @ts-expect-error
      if (userData.company) {
        const onSuccess = (response) => {
          store.dispatch(
            'company/setCompany',
            response,
          )
          commit(
            'SET_USER',
            {
              user,
              userData,
            },
          )
        }

        // @ts-expect-error
        getDoc(userData.company).then(onSuccess)
      }
      else {
        commit(
          'SET_USER',
          {
            user,
            userData,
          },
        )
      }
    },
    resetPassword({ commit }, { code, password }) {
      commit('RESET_PASSWORD_REQUEST')

      const onSuccess = () => {
        commit('RESET_PASSWORD_SUCCESS')
      }

      const onError = (error) => {
        console.error(error)
        console.error(error)
        commit(
          'RESET_PASSWORD_FAILURE',
          error,
        )
      }

      const codeValid = () => {
        confirmPasswordReset(
          auth,
          code,
          password,
        )
          .then(onSuccess)
          .catch(onError)
      }

      verifyPasswordResetCode(
        auth,
        code,
      ).then(codeValid).catch(onError)
    },
    verifyEmail({ commit }, { code }) {
      commit('VERIFY_EMAIL_REQUEST')

      const onSuccess = () => {
        commit('VERIFY_EMAIL_SUCCESS')
      }

      const onError = (error) => {
        console.error(error)
        console.error(error)
        commit(
          'VERIFY_EMAIL_FAILURE',
          error,
        )
      }

      applyActionCode(
        auth,
        code,
      )
        .then(onSuccess)
        .catch(onError)
    },
    updateUserData({ commit, state: { user } }, userData) {
      commit(
        'SET_USER',
        {
          user,
          userData,
        },
      )
    },
    signOut({ commit }) {
      const onSuccess = () => {
        commit(
          'SET_USER',
          {
            user: null,
            userData: null,
          },
        )

        useCompanies().resetState()
        useMessages().resetState()
        useReportBugOrSuggestion().resetState()
        useMissingCompanies().resetState()
        useNewsStore().resetState()
        useSuppliersSubsidyStore().resetState()
        useOrdersStatistic().resetState()
        useOnlineTransactionReports().resetState()
        useInvoices().resetState()
        useCoupons().resetState()
        useManagerRoutes().resetState()
        useMenus().resetState()
        store.dispatch('company/resetState')
        store.dispatch('shared/resetState')
        store.dispatch('employees/resetState')
        store.dispatch('orders/resetState')
        store.dispatch('routes/resetState')
        store.dispatch('suppliers/resetState')
        store.dispatch('app/resetState')
      }
      signOut(auth).then(onSuccess)
    },
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },
    PASSWORD_LOGIN_REQUEST(_state) {
      _state.error = null
      _state.loading = true
    },
    PASSWORD_LOGIN_SUCCESS(_state, userData) {
      _state.userData = userData
      _state.loading = false
    },
    PASSWORD_LOGIN_FAILURE(_state, error) {
      _state.loading = false
      _state.error = error
    },
    SET_USER_COPY(_state, { user }) {
      _state.user = user
    },
    SET_USER(_state, { user, userData }) {
      _state.user = user
      _state.userData = userData
    },
    SET_USER_DATA_REQUEST(_state) {
      _state.error = null
      _state.loading = true
    },
    SET_USER_DATA_SUCCESS(_state, userData) {
      _state.userData = userData
      _state.loading = false
    },
    SET_USER_DATA_FAILURE(_state, error) {
      _state.loading = false
      _state.error = error
    },
    RESET_PASSWORD_REQUEST(_state) {
      _state.error = null
      _state.loading = true
    },
    RESET_PASSWORD_SUCCESS(_state) {
      _state.loading = false
    },
    RESET_PASSWORD_FAILURE(_state, error) {
      _state.loading = false
      _state.error = error
    },
    VERIFY_EMAIL_REQUEST(_state) {
      _state.error = null
      _state.loading = true
    },
    VERIFY_EMAIL_SUCCESS(_state) {
      _state.loading = false
    },
    VERIFY_EMAIL_FAILURE(_state, error) {
      _state.loading = false
      _state.error = error
    },
  },
}
