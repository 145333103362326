import type { DocumentData, QuerySnapshot } from 'firebase/firestore'
import type { SupplierModel } from '../models/SupplierModel'
import { addDoc, deleteDoc, doc, endAt, getDocs, increment, onSnapshot, orderBy, query, serverTimestamp, startAt, updateDoc, where, writeBatch } from 'firebase/firestore'
import { distanceBetween } from 'geofire-common'
import store from '~/store'
import { firestore } from '../firebaseCore'
import {
  collectionMenuCategories,
  collectionSupplier,
  currentOrdersRef,
  getNotSentNotificationSuppliersByCompany,
  getNotSentNotificationSuppliersByCompany2,
  getNotSentNotificationSuppliersByCompanyCity,
  getNotSentNotificationSuppliersByCompanyCity2,
  getSupplierFromHomeDelivery,
  getSupplierFromHomeDeliveryCity,
  getSuppliersByCompanyRoute,
  getSuppliersByCompanyRouteCity,
  getSuppliersSearch,
  getSuppliersSearcheCity,
  getSuppliersSubsidyByCompanyRoute,
  getSuppliiersByCode,
} from '../firestoreWrappers'
import { getIdFromRef } from '../helpers/getIdFromRef'
import { isManagerOrDeliver, isOwner } from '../helpers/roles'
import { errorDefault, errorEmail, savedDefault } from '../helpers/snackbar'
import {
  mapNotSentNotificationSuppliers,
  mapSuppliers,
  mapSuppliersHomeDelivery,
  mapSuppliersSubsidy,
} from '../models/SupplierModel'
import { mapOrders, Order } from './orders'

function findUniqueCode(success) {
  const code = Math.round(100000 + Math.random() * 900000)
  const onSuccess = ({ docs }) => {
    if (docs.length)
      findUniqueCode(success)
    else
      success(code)
  }

  getDocs(getSuppliiersByCode(code))
    .then(onSuccess)
}

function initialState() {
  return {
    createError: null,
    createLoading: false,
    delivers: null,
    fetchAdditionalError: null,
    fetchAdditionalLoading: false,
    fetchError: null,
    fetchLoading: false,
    fetchLoadingSubsidy: false,
    fetchNotSentNotificationError: null,
    fetchNotSentNotificationLoading: true,
    notSentNotificationSuppliers: null,
    notSentNotificationSuppliers2: null,
    supplierLast: null,
    suppliers: null,
    suppliersAdditional: null,
    suppliersHomeDelivery: null,
    suppliersSubsidy: null,
    suppliersInArea: null,
    unsubscribe: null,
    unsubscribe2: null,

    suppliersSearch: null,
  }
}

export default {
  namespaced: true,
  state: initialState(),
  actions: {
    resetState({
      commit,
      rootState: {
        suppliers: { unsubscribe, unsubscribe2 },

      },
    }) {
      if (unsubscribe)
        unsubscribe()

      if (unsubscribe2)
        unsubscribe2()

      commit('RESET_STATE')
    },
    resetSearchSuppliers({
      commit,
    }) {
      commit('RESET_SEARCH_SUPPLIERS')
    },

    getSuppliers(
      {
        commit,
        rootState: {
          app: {
            userData,
            user: { displayName },
          },
        },
      },
      routeRef,
    ) {
      if (!routeRef || !userData?.company)
        return

      const {
        company,
        profile: { cities },
      } = userData

      commit('GET_SUPPLIERS_REQUEST')

      const onSuccess = ({ docs }) => {
        commit(
          'GET_SUPPLIERS_SUCCESS',
          { suppliers: docs.map(mapSuppliers(routeRef)) },
        )
      }
      const onError = (error) => {
        console.error(error)
        commit(
          'GET_SUPPLIERS_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      if (isManagerOrDeliver(displayName)) {
        getDocs(getSuppliersByCompanyRouteCity(
          company,
          routeRef,
          cities,
        ))
          .then(onSuccess)
          .catch(onError)
      }
      else if (isOwner(displayName)) {
        getDocs(getSuppliersByCompanyRoute(
          company,
          routeRef,
        ))
          .then(onSuccess)
          .catch(onError)
      }
    },

    updateSuppliersArray(
      {
        commit,
        rootState: {
          app: { userData },
        },
      },
      { suppliers, value },
    ) {
      commit('UPDATE_SUPPLIERS_BUY_ONLINE_REQUEST')

      const batch = writeBatch(firestore)

      suppliers.forEach((item) => {
        batch.update(
          item.reference,
          {
            isBuyOnline: value,
            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        )
      })

      const onSuccess = () => {
        commit(
          'UPDATE_SUPPLIERS_BUY_ONLINE_SUCCESS',
          {
            data: suppliers,
            isBuyOnline: value,
          },
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'UPDATE_SUPPLIERS_BUY_ONLINE_ERROR',
          error,
        )
      }

      batch.commit()
        .then(onSuccess)
        .catch(onError)
    },

    getSuppliersSearch(
      {
        commit,
        rootState: {
          app: {
            userData,
            user: { displayName },
          },
        },
      },
      search,
    ) {
      if (!userData?.company)
        return

      const {
        company,
        profile: { cities },
      } = userData

      commit('GET_SUPPLIERS_SEARCH_REQUEST')

      const onSuccess = ({ docs }) => {
        const data = docs.map(mapSuppliersHomeDelivery)

        if (isManagerOrDeliver(displayName)) {
          commit(
            'GET_SUPPLIERS_SEARCH_SUCCESS',
            { suppliers: data.filter(item => cities.includes(item.place.city)) },
          )
        }
        else if (isOwner(displayName)) {
          commit(
            'GET_SUPPLIERS_SEARCH_SUCCESS',
            { suppliers: docs.map(mapSuppliersHomeDelivery) },
          )
        }
      }
      const onError = (error) => {
        console.error(error)
        commit(
          'GET_SUPPLIERS_SEARCH_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }
      if (isManagerOrDeliver(displayName)) {
        getDocs(getSuppliersSearcheCity(
          company,
          cities,
          search,
        ))
          .then(onSuccess)
          .catch(onError)
      }
      else if (isOwner(displayName)) {
        getDocs(getSuppliersSearch(
          company,
          search,
        ))
          .then(onSuccess)
          .catch(onError)
      }
    },

    getSuppliersSubsidy({
      commit,
      rootState: {
        app: {
          userData: {
            company,
          },
          user: { displayName },
        },
      },
    }) {
      if (!displayName)
        return

      commit('GET_SUPPLIERS_SUBSIDY_REQUEST')

      const onSuccess = ({ docs }) => {
        commit(
          'GET_SUPPLIERS_SUBSIDY_SUCCESS',
          docs.map(mapSuppliersSubsidy),
        )
      }
      const onError = (error) => {
        console.error(error)
        commit(
          'GET_SUPPLIERS_SUBSIDY_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      getDocs(getSuppliersSubsidyByCompanyRoute(company))
        .then(onSuccess)
        .catch(onError)
    },

    getNotSentNotificationSuppliers({
      commit,
      rootState: {
        routes: { routes },
        suppliers: { unsubscribe, unsubscribe2 },
        app: {
          userData: {
            company,
            profile: { cities },
          },
          user: { displayName },
        },
      },
    }) {
      if (unsubscribe) {
        unsubscribe()
        commit(
          'SAVE_UNSUBSCRIBE',
          null,
        )
      }
      if (unsubscribe2) {
        unsubscribe2()
        commit(
          'SAVE_UNSUBSCRIBE_2',
          null,
        )
      }

      commit('GET_NOT_SENT_NOTIFICATION_SUPPLIERS_REQUEST')
      const onSuccess = ({ docs }) => {
        commit(
          'GET_NOT_SENT_NOTIFICATION_SUPPLIERS_SUCCESS',
          { suppliers: docs.map(mapNotSentNotificationSuppliers(routes)) },
        )
      }
      const onSuccess2 = ({ docs }) => {
        commit(
          'GET_NOT_SENT_NOTIFICATION_SUPPLIERS_SUCCESS_2',
          { suppliers: docs.map(mapNotSentNotificationSuppliers(routes)) },
        )
      }
      const onError = (error) => {
        console.error(error)
        commit(
          'GET_NOT_SENT_NOTIFICATION_SUPPLIERS_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      if (isManagerOrDeliver(displayName) || isOwner(displayName)) {
        const query1 = isOwner(displayName)
          ? getNotSentNotificationSuppliersByCompany(company)
          : getNotSentNotificationSuppliersByCompanyCity(
            company,
            cities,
          )

        const unsubscribeNew = onSnapshot(
          query1,
          onSuccess,
          onError,
        )

        commit(
          'SAVE_UNSUBSCRIBE',
          unsubscribeNew,
        )

        const query2 = isOwner(displayName)
          ? getNotSentNotificationSuppliersByCompany2(company)
          : getNotSentNotificationSuppliersByCompanyCity2(
            company,
            cities,
          )

        const unsubscribeNew2 = onSnapshot(
          query2,
          onSuccess2,
          onError,
        )

        commit(
          'SAVE_UNSUBSCRIBE_2',
          unsubscribeNew2,
        )
      }
      else {
        onError('Wrong site')
      }
    },
    getAdditionalSuppliers(
      {
        commit,
        rootState: {
          app: {
            userData: {
              company,
              profile: { cities },
            },
            user: { displayName },
          },
        },
      },
      routeRef,
    ) {
      if (!routeRef)
        return

      commit('GET_ADDITIONAL_SUPPLIERS_REQUEST')

      const onSuccess = ({ docs }) => {
        commit(
          'GET_ADDITIONAL_SUPPLIERS_SUCCESS',
          { suppliers: docs.map(mapSuppliers(routeRef)) },
        )
      }
      const onError = (error) => {
        console.error(error)
        commit(
          'GET_ADDITIONAL_SUPPLIERS_FAILURE',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
      }

      if (isManagerOrDeliver(displayName)) {
        getDocs(getSuppliersByCompanyRouteCity(
          company,
          routeRef,
          cities,
        ))
          .then(onSuccess)
          .catch(onError)
      }
      else if (isOwner(displayName)) {
        getDocs(getSuppliersByCompanyRoute(
          company,
          routeRef,
        ))
          .then(onSuccess)
          .catch(onError)
      }
    },
    getHomeDeliverySuppliers({
      commit,
      rootState: {
        app: {
          userData: {
            company,
            profile: { cities },
          },
          user: { displayName },
        },
      },
    }) {
      store.dispatch('shared/clearError')
      store.dispatch(
        'shared/setLoading',
        true,
      )

      const onSuccess = ({ docs }) => {
        store.dispatch(
          'shared/setLoading',
          false,
        )

        commit(
          'GET_SUPPLIERS_HOME_DELIVERY_SUCCESS',
          { suppliers: docs.map(mapSuppliersHomeDelivery) },
        )
      }
      const onError = (error) => {
        console.error(error)
        store.dispatch(
          'shared/setError',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
        store.dispatch(
          'shared/setLoading',
          false,
        )
      }

      if (isManagerOrDeliver(displayName)) {
        getDocs(getSupplierFromHomeDeliveryCity(
          company,
          cities,
        ))
          .then(onSuccess)
          .catch(onError)
      }
      else if (isOwner(displayName)) {
        getDocs(getSupplierFromHomeDelivery(company))
          .then(onSuccess)
          .catch(onError)
      }
    },
    createSupplier({
      commit,
      state: { suppliers },
      rootState: {
        app: {
          userData,
        },
      },
    }, { supplier }) {
      commit('CREATE_SUPPLIERS_REQUEST')
      const newSuppliers = [...suppliers]
      supplier.menu = doc(
        collectionMenuCategories,
        getIdFromRef(supplier.company),
      )

      findUniqueCode((uniqueCode) => {
        let createdSupplierRef

        const onSuccesCreateSupplier = (supplierRef) => {
          createdSupplierRef = supplierRef
          supplier.reference = supplierRef
          newSuppliers.push(supplier)
          commit(
            'CREATE_SUPPLIERS_SUCCESS',
            newSuppliers,
          )
          store.dispatch(
            'snackbar/showSnackbar',
            savedDefault,
          )
        }

        const onErrorCreateSupplier = (error) => {
          const deleteData = () => {
            commit(
              'CREATE_SUPPLIERS_FAILURE',
              error,
            )
            store.dispatch(
              'snackbar/showSnackbar',
              errorEmail(error),
            )
          }
          deleteDoc(createdSupplierRef).then(deleteData)
        }

        supplier.code = String(uniqueCode)
        supplier.tags = [
          ...supplier.tags,
          String(uniqueCode),
        ]

        addDoc(
          collectionSupplier,
          {
            ...supplier.toMap(),

            createdByUser: userData.reference,
            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        )
          .then(onSuccesCreateSupplier)
          .catch(onErrorCreateSupplier)
      })
    },
    editSupplier({
      commit,
      state: { suppliers },
      rootState: {
        app: {
          userData,
        },
      },
    }, { supplier }) {
      commit('EDIT_SUPPLIERS_SEARCH_REQUEST_1')
      const newSuppliers = [...suppliers]
      const supplierIndex = newSuppliers.findIndex(value => getIdFromRef(value.reference) == getIdFromRef(supplier.reference))

      const supplierCopy = supplier
      supplierCopy.lastUpdateByUser = userData.reference
      supplierCopy.lastUpdateTime = serverTimestamp()

      updateDoc(
        supplier.reference,
        supplierCopy.toMap(),
      )
        .then(() => {
          newSuppliers[supplierIndex] = supplierCopy
          commit(
            'EDIT_SUPPLIERS_SUCCESS',
            newSuppliers,
          )
          store.dispatch(
            'snackbar/showSnackbar',
            savedDefault,
          )
        })
        .catch((error) => {
          commit(
            'EDIT_SUPPLIERS_SEARCH_FAILURE',
            error,
          )
          store.dispatch(
            'snackbar/showSnackbar',
            errorEmail(error),
          )
        })
    },
    setDelivers({ commit }, { delivers }) {
      commit(
        'SET_DELIVERS_REQUEST',
        delivers,
      )
    },

    editSupplierSupplierView({
      commit,
      state: { suppliersSearch },
      rootState: {
        app: {
          userData,
        },
      },
    }, { supplier }) {
      commit('EDIT_SUPPLIERS_SEARCH_REQUEST_1')
      const newSuppliers = [...suppliersSearch]
      const supplierIndex = newSuppliers.findIndex(value => getIdFromRef(value.reference) == getIdFromRef(supplier.reference))

      const supplierCopy = supplier
      supplierCopy.lastUpdateByUser = userData.reference
      supplierCopy.lastUpdateTime = serverTimestamp()

      updateDoc(
        supplier.reference,
        supplierCopy.toMap(),
      )
        .then(() => {
          newSuppliers[supplierIndex] = supplierCopy
          commit(
            'EDIT_SUPPLIERS_SEARCH_SUCCESS',
            newSuppliers,
          )
          store.dispatch(
            'snackbar/showSnackbar',
            savedDefault,
          )
        })
        .catch((error) => {
          commit(
            'EDIT_SUPPLIERS_SEARCH_FAILURE',
            error,
          )
          store.dispatch(
            'snackbar/showSnackbar',
            errorEmail(error),
          )
        })
    },
    updateOrder(
      {
        commit,
        rootState: {
          suppliers: { suppliers },
          app: { userData },
        },
      },
      { supplier, pending, approved },
    ) {
      commit('UPDATE_SUPPLIERS_REQUEST')
      const onSuccess = () => {
        const updateOnlySelectedSupplier = (supplierData) => {
          if (supplierData.reference.id === supplier.id) {
            const tmpSupplier = supplierData
            tmpSupplier.pendingOrders += pending
            tmpSupplier.approvedOrders += approved

            return tmpSupplier
          }

          return supplierData
        }
        const mapped = suppliers.map(updateOnlySelectedSupplier)
        commit(
          'UPDATE_SUPPLIERS_SUCCESS',
          { suppliers: mapped },
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'UPDATE_SUPPLIERS_FAILURE',
          error,
        )
      }

      const findSupplierData = supplierData => supplierData.reference.id === supplier.id

      const [currentSupplierData] = suppliers.filter(findSupplierData)

      const updatePendingOrders = updateDoc(
        currentSupplierData.reference,
        {
          pendingOrders: increment(pending),
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      )
      const updateCurrentSupplierData = updateDoc(
        currentSupplierData.reference,
        {
          approvedOrders: increment(approved),
          lastUpdateByUser: userData.reference,
          lastUpdateTime: serverTimestamp(),
        },
      )
      Promise.all([
        updatePendingOrders,
        updateCurrentSupplierData,
      ])
        .then(onSuccess)
        .catch(onError)
    },
    changeSupplierRoute(
      {
        commit,
        rootState: {
          suppliers: { suppliers },
          routes: { routes },
          app: { userData },
        },
      },
      { supplier, routeReference: newRouteReference },
    ) {
      store.dispatch('shared/clearError')
      store.dispatch(
        'shared/setLoading',
        true,
      )

      const onError = (error) => {
        console.error(error)
        store.dispatch(
          'shared/setError',
          error,
        )
        store.dispatch(
          'snackbar/showSnackbar',
          errorDefault(error),
        )
        store.dispatch(
          'shared/setLoading',
          false,
        )
      }
      const onSuccessGetCurrentOrders = ({ docs }) => {
        const supplierOrders = docs.map(mapOrders) || []
        const sumFunc = (sum, { completementStatus }) => (
          completementStatus === Order.completementStatus.waitingCustomerAccept
          || completementStatus === Order.completementStatus.waitingFirmAccept
            ? sum + 1
            : sum
        )

        const pendingOrdersCount = supplierOrders.reduce(
          sumFunc,
          0,
        )

        const byId = ({ reference: { id } }) => id === newRouteReference.id
        const [route] = routes.filter(byId)

        const onSuccess = () => {
          const updateSupplier = ({ reference: { id } }) => supplier.reference.id !== id

          const updatedRoutes = routes.map((routeData) => {
            if (routeData.reference.id === supplier.route.id) {
              routeData.ordersCount -= supplierOrders.length || 0
              routeData.pendingOrders -= pendingOrdersCount || 0
            }
            else if (routeData.reference.id === route.reference.id) {
              routeData.ordersCount += supplierOrders.length || 0
              routeData.pendingOrders += pendingOrdersCount || 0
            }

            return routeData
          })
          store.dispatch(
            'routes/updateRouteData',
            updatedRoutes,
          )

          commit(
            'GET_SUPPLIERS_SUCCESS',
            {
              suppliers: suppliers.filter(updateSupplier),
            },
          )
          store.dispatch(
            'shared/setLoading',
            false,
          )
        }

        const updateOldRoute = updateDoc(
          supplier.route,
          {
            ordersCount: increment(-supplierOrders.length || 0),
            pendingOrders: increment(-pendingOrdersCount || 0),

            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        )
        const updateNewRoute = updateDoc(
          route.reference,
          {
            ordersCount: increment(supplierOrders.length || 0),
            pendingOrders: increment(pendingOrdersCount || 0),

            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        )

        const supplierOrdersToUpdate = supplierOrders.map(order => updateDoc(
          order.reference,
          {
            route: route.reference,
            deliver: route.isSubDeliver
              ? route.subDeliver
              : route.deliver,
            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        ))
        const updateSupplier = updateDoc(
          supplier.reference,
          {
            route: newRouteReference,
            deliverID: route.isSubDeliver
              ? route.subDeliver
              : route.deliver,
            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        )

        Promise.all([
          updateOldRoute,
          updateNewRoute,
          updateSupplier,
          ...supplierOrdersToUpdate,
        ])
          .then(onSuccess)
          .catch(onError)
      }

      getDocs(currentOrdersRef(supplier))
        .then(onSuccessGetCurrentOrders)
        .catch(onError)
    },

    changeActivity(
      {
        commit,
        rootState: {
          app: { userData },
        },
      },
      { data, isActive },
    ) {
      commit('UPDATE_SUPPLIERS_STATUS_REQUEST')

      const batch = writeBatch(firestore)

      data.forEach((item) => {
        batch.update(
          item.reference,
          {
            isActive,
            lastUpdateByUser: userData.reference,
            lastUpdateTime: serverTimestamp(),
          },
        )
      })

      const onSuccess = () => {
        commit(
          'UPDATE_SUPPLIERS_STATUS_SUCCESS',
          {
            data,
            isActive,
          },
        )
      }

      const onError = (error) => {
        console.error(error)
        commit(
          'UPDATE_SUPPLIERS_STATUS_ERROR',
          error,
        )
      }

      batch.commit()
        .then(onSuccess)
        .catch(onError)
    },
    getSuppliersWithinBounds(
      {
        commit,
      },
      { bounds, center, radiusInM, company },
    ) {
      commit('GET_SUPPLIERS_WITHIN_BOUNDS_REQUEST')

      const onSuccess = (data) => {
        const matchingDocs: Array<SupplierModel> = []
        for (const snap of data) {
          for (const doc1 of snap.docs) {
            const lat = doc1.get('geoPoint')

            const distanceInKm = distanceBetween([lat._lat, lat._long], center)
            const distanceInM = distanceInKm * 1000
            if (distanceInM <= radiusInM)
              matchingDocs.push(mapSuppliersSubsidy(doc1))
          }
        }
        commit(
          'GET_SUPPLIERS_WITHIN_BOUNDS_SUCCESS',
          {
            data: matchingDocs,
          },
        )
      }

      const onError = (error) => {
        commit(
          'GET_SUPPLIERS_WITHIN_BOUNDS_FAILURE',
          error,
        )
      }

      const promises: Promise<QuerySnapshot<DocumentData, DocumentData>> [] = []
      for (const b of bounds) {
        const q = query(
          collectionSupplier,
          where('company', '==', company),
          orderBy('position.geohash'),
          startAt(b[0]),
          endAt(b[1]),
        )

        promises.push(getDocs(q))
      }

      Promise.all(promises)
        .then(onSuccess)
        .catch(onError)
    },
    updateSuppliersAreaLocally(
      {
        commit,
      },
      { suppliers },
    ) {
      commit('UPDATE_SUPPLIERS_AREA_LOCALLY', suppliers)
    },
  },
  mutations: {
    RESET_STATE(_state) {
      Object.assign(
        _state,
        initialState(),
      )
    },

    SAVE_UNSUBSCRIBE(state, unsubscribe) {
      state.unsubscribe = unsubscribe
    },
    SAVE_UNSUBSCRIBE_2(state, unsubscribe) {
      state.unsubscribe2 = unsubscribe
    },

    GET_SUPPLIERS_REQUEST(_state) {
      _state.fetchError = null
      _state.fetchLoading = true
    },
    GET_SUPPLIERS_SUCCESS(_state, { suppliers }) {
      _state.fetchLoading = false
      _state.fetchError = null
      _state.suppliers = suppliers
    },
    GET_SUPPLIERS_FAILURE(_state, error) {
      _state.fetchLoading = false
      _state.fetchAdditionalLoading = false
      _state.fetchAdditionalError = error
      _state.fetchError = error
    },

    GET_SUPPLIERS_SEARCH_REQUEST(_state) {
      _state.fetchError = null
      _state.fetchLoading = true
    },
    GET_SUPPLIERS_SEARCH_SUCCESS(_state, { suppliers }) {
      _state.fetchLoading = false
      _state.fetchError = null
      _state.suppliersSearch = suppliers
    },
    GET_SUPPLIERS_SEARCH_FAILURE(_state, error) {
      _state.fetchLoading = false
      _state.fetchAdditionalLoading = false
      _state.fetchAdditionalError = error
      _state.fetchError = error
    },

    GET_SUPPLIERS_SUBSIDY_REQUEST(_state) {
      _state.fetchError = null
      _state.fetchLoadingSubsidy = true
    },
    GET_SUPPLIERS_SUBSIDY_SUCCESS(_state, suppliersSubsidy) {
      _state.fetchLoadingSubsidy = false
      _state.fetchError = null
      _state.suppliersSubsidy = suppliersSubsidy
    },
    GET_SUPPLIERS_SUBSIDY_FAILURE(_state, error) {
      _state.fetchLoadingSubsidy = false
      _state.fetchError = error
    },

    GET_NOT_SENT_NOTIFICATION_SUPPLIERS_REQUEST(_state) {
      _state.fetchNotSentNotificationError = null
      _state.fetchNotSentNotificationLoading = true
    },
    GET_NOT_SENT_NOTIFICATION_SUPPLIERS_SUCCESS(_state, { suppliers }) {
      _state.fetchNotSentNotificationLoading = false
      _state.fetchNotSentNotificationError = null
      _state.notSentNotificationSuppliers = suppliers
    },
    GET_NOT_SENT_NOTIFICATION_SUPPLIERS_SUCCESS_2(_state, { suppliers }) {
      _state.fetchNotSentNotificationLoading = false
      _state.fetchNotSentNotificationError = null
      _state.notSentNotificationSuppliers2 = suppliers
    },
    GET_NOT_SENT_NOTIFICATION_SUPPLIERS_FAILURE(_state, error) {
      _state.fetchNotSentNotificationLoading = false
      _state.fetchNotSentNotificationError = error
    },
    GET_ADDITIONAL_SUPPLIERS_REQUEST(_state) {
      _state.fetchAdditionalError = null
      _state.fetchAdditionalLoading = true
    },
    GET_ADDITIONAL_SUPPLIERS_SUCCESS(_state, { suppliers }) {
      _state.fetchAdditionalLoading = false
      _state.fetchAdditionalError = null
      _state.suppliersAdditional = suppliers
    },
    GET_ADDITIONAL_SUPPLIERS_FAILURE(_state, error) {
      _state.fetchLoading = false
      _state.fetchError = error
    },

    GET_SUPPLIERS_HOME_DELIVERY_SUCCESS(_state, { suppliers }) {
      _state.suppliersHomeDelivery = suppliers
    },

    GET_MORE_SUPPLIERS_REQUEST(_state) {
      _state.fetchMoreError = null
      _state.fetchMoreLoading = true
    },
    GET_MORE_SUPPLIERS_SUCCESS(_state, { suppliers, delivers, supplierLast }) {
      _state.fetchMoreLoading = false
      _state.fetchMoreError = null
      _state.suppliers = suppliers
      _state.supplierLast = supplierLast
      _state.delivers = delivers
    },
    GET_MORE_SUPPLIERS_FAILURE(_state, error) {
      _state.fetchMoreLoading = false
      _state.fetchMoreError = error
    },

    CREATE_SUPPLIERS_REQUEST(_state) {
      _state.createError = null
      _state.createLoading = true
    },
    CREATE_SUPPLIERS_SUCCESS(_state, suppliers) {
      _state.createLoading = false
      _state.createError = null
      _state.suppliers = suppliers
    },
    CREATE_SUPPLIERS_FAILURE(_state, error) {
      _state.createLoading = false
      _state.createError = error
    },
    EDIT_SUPPLIERS_SEARCH_REQUEST_1(_state) {
      _state.createError = null
      _state.createLoading = true
    },
    RESET_SEARCH_SUPPLIERS(_state) {
      _state.suppliersSearch = null
    },
    EDIT_SUPPLIERS_SUCCESS(_state, suppliers) {
      _state.createLoading = false
      _state.createError = null
      _state.suppliers = suppliers
    },
    EDIT_SUPPLIERS_SEARCH_SUCCESS(_state, suppliers) {
      _state.createLoading = false
      _state.createError = null
      _state.suppliersSearch = suppliers
    },
    EDIT_SUPPLIERS_SEARCH_FAILURE(_state, error) {
      _state.createLoading = false
      _state.createError = error
    },
    SET_DELIVERS_REQUEST(_state, delivers) {
      _state.delivers = delivers
    },

    UPDATE_SUPPLIERS_REQUEST(_state) {
      _state.fetchError = null
      _state.fetchLoading = true
    },
    UPDATE_SUPPLIERS_SUCCESS(_state, { suppliers }) {
      _state.fetchLoading = false
      _state.fetchError = null
      _state.suppliers = suppliers
    },
    UPDATE_SUPPLIERS_FAILURE(_state, error) {
      _state.fetchLoading = false
      _state.fetchError = error
    },

    UPDATE_SUPPLIERS_STATUS_REQUEST(_state) {
      _state.fetchError = null
      _state.fetchLoading = true
    },
    UPDATE_SUPPLIERS_STATUS_SUCCESS(_state, { data, isActive }) {
      const getId = item => item.reference.id
      const dataRefs = data.map(getId)

      const filterAndUpdateItems = (item) => {
        if (dataRefs.includes(item.reference.id)) {
          const tmpItem = item
          tmpItem.isActive = isActive

          return tmpItem
        }

        return item
      }
      _state.fetchLoading = false
      _state.fetchError = null
      _state.suppliers = _state.suppliers.map(filterAndUpdateItems)
    },
    UPDATE_SUPPLIERS_STATUS_ERROR(_state, error) {
      _state.fetchLoading = false
      _state.fetchAdditionalLoading = false
      _state.fetchAdditionalError = error
      _state.fetchError = error
    },

    UPDATE_SUPPLIERS_BUY_ONLINE_REQUEST(_state) {
      _state.fetchError = null
      _state.fetchLoading = true
    },
    UPDATE_SUPPLIERS_BUY_ONLINE_SUCCESS(_state, { data, isBuyOnline }) {
      const getId = item => item.reference.id
      const dataRefs = data.map(getId)

      const filterAndUpdateItems = (item) => {
        if (dataRefs.includes(item.reference.id)) {
          const tmpItem = item
          tmpItem.isBuyOnline = isBuyOnline

          return tmpItem
        }

        return item
      }
      _state.fetchLoading = false
      _state.fetchError = null
      _state.suppliers = _state.suppliers.map(filterAndUpdateItems)
    },
    UPDATE_SUPPLIERS_BUY_ONLINE_ERROR(_state, error) {
      _state.fetchLoading = false
      _state.fetchAdditionalLoading = false
      _state.fetchAdditionalError = error
      _state.fetchError = error
    },

    UPDATE_SUPPLIERS_ARRAY_INIT(_state) {
      _state.fetchError = null
      _state.fetchLoading = true
    },
    UPDATE_SUPPLIERS_ARRAY_SUCCESS(_state, { suppliers }) {
      _state.fetchLoading = false
      _state.fetchError = null
      _state.suppliers = suppliers
    },
    UPDATE_SUPPLIERS_ARRAY_FAILURE(_state, error) {
      _state.fetchLoading = false
      _state.fetchError = error
    },
    GET_SUPPLIERS_WITHIN_BOUNDS_REQUEST(_state) {
      _state.fetchError = null
      _state.fetchLoading = true
    },
    GET_SUPPLIERS_WITHIN_BOUNDS_SUCCESS(_state, suppliers) {
      _state.fetchLoading = false
      _state.fetchError = null
      _state.suppliers = [...suppliers.data]
      _state.suppliersInArea = [...suppliers.data]
    },
    GET_SUPPLIERS_WITHIN_BOUNDS_FAILURE(_state, error) {
      _state.fetchLoading = false
      _state.fetchError = error
    },
    UPDATE_SUPPLIERS_AREA_LOCALLY(_state, suppliers) {
      _state.fetchLoading = false
      _state.fetchError = null
      _state.suppliersInArea = suppliers
    },
  },
}
